import React, {MouseEvent, MouseEventHandler} from "react";
import {EstimateType} from "@estimate/types/estimate.types";
import {Button, Col, Row, Tab, Tabs} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faEuro,
    faInfoCircle,
    faScrewdriverWrench,
    faSpinner
} from "@fortawesome/pro-light-svg-icons";
import {ProcessInfo} from "@process/components";
import {
    EstimateInfoComponent as EstimateInfo, EstimateItemCreate,
    EstimateItemFormInlineComponent as EstimateItemFormInline,
    EstimateItemListComponent as EstimateItemList,
    EstimateSummaryComponent as EstimateSummary,
} from "@estimate/components";
import {faDownload} from "@fortawesome/pro-regular-svg-icons";
import {AxiosResponse} from "axios";
import {downloadFile} from "@src/helpers/utils.helper";
import {downloadEstimateFile} from "@estimate/utils/axios";

interface EstimateCardProps{
    estimate: EstimateType,
    fetchEstimate: Function
}

const EstimateCardComponent: React.FC<EstimateCardProps> = (
    {estimate, fetchEstimate}
) => {
    const [isDownloadingEstimtePdf, setDownloadingEstimtePdf] = React.useState<boolean>(false);

    const downloadEstimatePdfHandler: React.MouseEventHandler = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        if (isDownloadingEstimtePdf) {
            return;
        }

        setDownloadingEstimtePdf(true);
        downloadEstimateFile(estimate.id)
            .then((response: AxiosResponse<Blob>) => {
                const fileName = response.headers["content-disposition"]?.split(";")[1]?.replace(/filename=/g, "")
                    ?? 'kosztorys.pdf';
                downloadFile(response.data, fileName);
            })
            .finally(() => { setDownloadingEstimtePdf(false) })
    }

    return (
        <>
            <Row className="mb-4">
                <Col>
                    <Tabs
                        defaultActiveKey="process"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                    >
                        <Tab eventKey="process"
                             title={<><FontAwesomeIcon icon={faScrewdriverWrench} fixedWidth={true}/> Proces serwisowy</>}
                        >
                            <ProcessInfo process={estimate.process} />
                        </Tab>
                        <Tab eventKey="info"
                             title={<><FontAwesomeIcon icon={faInfoCircle} fixedWidth={true}/> Kosztorys</>}
                        >
                            <EstimateInfo estimate={estimate} />
                        </Tab>
                    </Tabs>
                </Col>
                <Col>
                    <Tabs defaultActiveKey="summary" className="mb-3">
                        <Tab eventKey="summary"
                             title={<><FontAwesomeIcon icon={faEuro} fixedWidth={true}/> Podsumowanie</>}
                        >
                            <EstimateSummary estimate={estimate} />
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button size={"sm"} variant={"outline-primary"} className={"mb-3"}
                        onClick={downloadEstimatePdfHandler}
                    >
                        {isDownloadingEstimtePdf ? (
                            <><FontAwesomeIcon icon={faSpinner} fixedWidth={true}/> pobieram kosztorys...</>
                        ) : (
                            <><FontAwesomeIcon icon={faDownload} fixedWidth={true}/> pobierz kosztorys</>
                        )}
                    </Button>
                    <EstimateItemList estimate={estimate} fetchEstimate={fetchEstimate}/>
                    <EstimateItemFormInline estimate={estimate} fetchEstimate={fetchEstimate}/>
                    <EstimateItemCreate estimate={estimate} afterSuccessSave={fetchEstimate} />
                </Col>
            </Row>
        </>
    )
}

export default EstimateCardComponent;