import React, {useState} from "react";
import {Button, Form, Row, Col} from "react-bootstrap";
import {UserFormErrorType, UserType} from "@user/types/user.types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinnerThird} from "@fortawesome/pro-regular-svg-icons";
import Container from 'react-bootstrap/Container';
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {saveUser} from "@user/utils/axios";
import {DepartmentSelect} from "@src/modules/department/components";
import {mapUserToRequestType} from "@user/utils/mapper";
import {FormError} from "@src/components";
import {Maybe} from "@src/types/global.types";
import {DepartmentType} from "@department/types/department.types";

interface UserFormProps {
    user: UserType,
}

const UserFormComponent: React.FC<UserFormProps> = ({user: initialUser}) => {
    const navigate = useNavigate();
    const [formErrors, setFormErrors] = useState<UserFormErrorType>({});
    const [user, setUser] = useState<UserType>(initialUser);
    const [isSending, setIsSending] = useState<boolean>(false)

    const onSubmitHandler = e => {
        e.preventDefault();
        setIsSending(true);

        saveUser(mapUserToRequestType(user), user?.id ?? null)
            .then(response => {
                toast.success("Użytkownik został zapisany");
                navigate("/user/" + response.data.id);
            })
            .catch(error => {
                if (400 === error.response.status) {
                    const responseErrors = error.response?.data?.errors || {};
                    setFormErrors(responseErrors);
                } else {
                    console.log('Error', error.message);
                }
            })
            .finally(() => {
                setIsSending(false);
            })
    }

    const onChangeHandler = e => {
        const name = e.target.name;
        const type = e.target.type;
        let value = null;

        if (type === 'file') {
            value = e.target.files[0];
        } else if (type === 'checkbox') {
            value = e.target.checked;
        } else {
            value = e.target.value;
        }

        setUser((prevState: UserType) => {
            return {...prevState, ...{[name]: value}}
        });
    }

    const onChangeDepartmentHandler = (department: Maybe<DepartmentType>) => {
        setUser((prevState: UserType) => {
            return {...prevState, department}
        });
    }

    return (
        <Container className="component-user-form">
            <Form onSubmit={onSubmitHandler}>

                <Row>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Imię</Form.Label>
                            <Form.Control
                                type="text" value={user?.name ?? ""}
                                name="name"
                                onChange={onChangeHandler}
                                isInvalid={!!formErrors.name}
                            />
                            <FormError errors={formErrors.name} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Nazwisko</Form.Label>
                            <Form.Control
                                name="surname"
                                type="text" value={user?.surname ?? ""}
                                isInvalid={!!formErrors.surname}
                                onChange={onChangeHandler}
                            />
                            <FormError errors={formErrors.surname} />
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>E-mail</Form.Label>
                            <Form.Control
                                name="email"
                                type="text" value={user?.email ?? ""}
                                isInvalid={!!formErrors.email}
                                onChange={onChangeHandler}
                            />
                            <FormError errors={formErrors.email} />
                        </Form.Group>

                    </Col>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Telefon</Form.Label>
                            <Form.Control
                                name="phone"
                                type="text" value={user?.phone ?? ""}
                                isInvalid={!!formErrors.phone}
                                onChange={onChangeHandler}
                            />
                            <FormError errors={formErrors.phone} />
                        </Form.Group>
                    </Col>
                </Row>

                <Form.Group className="mb-3">
                    <Form.Label>Stanowisko</Form.Label>
                    <Form.Control
                        name="jobPosition"
                        type="text" value={user?.jobPosition ?? ""}
                        isInvalid={!!formErrors.jobPosition}
                        onChange={onChangeHandler}
                    />
                    <FormError errors={formErrors.jobPosition} />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Oddział</Form.Label>
                    <DepartmentSelect
                        name="department"
                        onChange={onChangeDepartmentHandler}
                        value={user.department}
                        isInvalid={!!formErrors.department}
                    />
                    <FormError errors={formErrors.department} />
                </Form.Group>

                <Button variant="primary" type="submit" disabled={isSending}>
                    {isSending ? (
                        <><FontAwesomeIcon icon={faSpinnerThird} spin={true}/> wysyłam</>
                    ) : 'Zapisz'}
                </Button>
            </Form>
        </Container>
    )
}

export default UserFormComponent;
