import React, {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGear, faPlus} from "@fortawesome/pro-regular-svg-icons";
import {parseFilterParamsFromUrl} from "@src/helpers/utils.helper";
import {ProcessFilterRequestType, ProcessType} from "@process/types/process.types";
import Table from "react-bootstrap/Table";
import {Pagination, SortableColumn} from "@src/components";
import {ProcessDropdownOptions, ProcessFilter} from "@process/components";
import {Maybe, PaginationDataType, PaginationType} from "@src/types/global.types";
import {getProcessCollection} from "@process/utils/axios";
import {Alert} from "react-bootstrap";

interface ProcessListProps {}

const ProcessListView: React.FC<ProcessListProps> = () => {
    const locationParams = useLocation().search;
    const [processCollection, setProcessCollection] = useState<ProcessType[]>([]);
    const [pagination, setPagination] = useState<Maybe<PaginationType>>();
    const [processFilter, setProcessFilter] = useState<ProcessFilterRequestType>(parseFilterParamsFromUrl(locationParams));
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isLoad, setLoad] = useState<boolean>(false);

    const fetchProcessCollection = () => {
        setLoading(true);
        getProcessCollection(processFilter).then((paginationData: PaginationDataType<ProcessType>) => {
            setProcessCollection(paginationData.items);
            setPagination(paginationData.pagination);
            setLoading(false);
            setLoad(true)
        });
    }

    useEffect(() => {
        const newProcessFilter: ProcessFilterRequestType = parseFilterParamsFromUrl(locationParams);
        if (JSON.stringify(newProcessFilter) !== JSON.stringify(processFilter)) {
            setProcessFilter(newProcessFilter);
        }
    }, [locationParams])

    useEffect(() => {
        fetchProcessCollection();
    }, [processFilter]);

    return (
        <div className="view-process-list">
            <div className="d-flex align-content-center align-items-center justify-content-between mb-3">
                <div>
                    <h1 className="m-0">Lista procesów serwisowych</h1>
                </div>
                <div>
                    <Link to="/process/create" className="btn btn-outline-primary">
                        <FontAwesomeIcon icon={faPlus} fixedWidth={true}/> Dodaj
                    </Link>
                </div>
            </div>

            <ProcessFilter processFilterRequest={processFilter} />
            {processCollection.length === 0 && isLoad && (
                <Alert variant="info">Brak wyników wyszukiwania</Alert>
            )}

            {processCollection.length > 0 && (
                <>
                    <Table hover bordered size="sm">
                        <thead>
                        <tr>
                            <th scope="col">
                                <SortableColumn label="Data" orderKey="createdAt" params={processFilter}/>
                            </th>
                            <th scope="col">
                                <SortableColumn label="Numer" orderKey="number" params={processFilter}/>
                            </th>
                            <th scope="col">
                                <SortableColumn label="Tytuł" orderKey="title" params={processFilter}/>
                            </th>
                            <th scope="col" className="text-center">
                                <SortableColumn label="Status" orderKey="status" params={processFilter}/>
                            </th>
                            <th scope="col">
                                <SortableColumn label="Przypisane do" orderKey="user" params={processFilter}/>
                            </th>
                            <th scope="col">
                                <SortableColumn label="Klient" orderKey="customer" params={processFilter}/>
                            </th>
                            <th scope="col" className="text-center" style={{width: 0}}>
                                <a href="#"><FontAwesomeIcon icon={faGear} /></a>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {processCollection.map((process: ProcessType) => (
                            <tr key={process.id}>
                                <td>{process.date}</td>
                                <td>
                                    <Link to={`/process/${process.id}`}>{process.number}</Link>
                                </td>
                                <td>
                                    <Link to={`/process/${process.id}`}>{process.title}</Link>
                                </td>
                                <td className="text-center">{process.status.name}</td>
                                <td>{`${process.user.name} ${process.user.surname}`}</td>
                                <td>
                                    {process.customer.companyName && <div>{process.customer.companyName}</div>}
                                    <div>{`${process.customer.firstName} ${process.customer.lastName}`}</div>
                                    {process.customer.email && <div>E-mail: {process.customer.email}</div>}
                                    {process.customer.phone && <div>Tel: {process.customer.phone}</div>}
                                </td>
                                <td>
                                    <ProcessDropdownOptions process={process} buttonSize="sm" buttonVariant="light" />
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    {pagination && <Pagination pagination={pagination} />}
                </>
            )}
        </div>
    )
};

export default ProcessListView;
