import React from "react";
import {Form} from "react-bootstrap";
import {CommonErrorType, Maybe} from "@src/types/global.types";

interface FormErrorProps {
    errors?: Maybe<CommonErrorType[]>
}

const FormErrorComponent:React.FC<FormErrorProps> = ({errors}) => {

    if (!errors){
        return;
    }

    return (
        <Form.Control.Feedback type="invalid">
            {errors.map((error, key) => <div key={key}>{error.message}</div>)}
        </Form.Control.Feedback>
    );
};

export default FormErrorComponent;