import {client as clientAxios} from "../../../library/axios.library";
import {AxiosResponse} from "axios";
import {UserChangePasswordFormType, UserFilterType, UserRequestType, UserType} from "@user/types/user.types"
import {PaginationDataType} from "@src/types/global.types";

export const getUser = async (id: string): Promise<UserType> => {
    const {data} = await clientAxios.get<null, AxiosResponse<UserType>>(`/api/v1/user/${id}`);
    return data;
}

export const getUserCollection = async (filter: UserFilterType): Promise<PaginationDataType<UserType>> => {
    const {data} = await clientAxios.post<UserFilterType, AxiosResponse<PaginationDataType<UserType>>>('/api/v1/user', filter);
    return data;
}

export const saveUser = async (data: UserRequestType, id?: string) => {
    if (id) {
        return updateUser(id, data);
    }

    return createUser(data);
}

export const createUser = async (data: UserRequestType) => {
    return await clientAxios.post<UserRequestType, AxiosResponse>('/api/v1/user/create', data);
}

export const updateUser = async (id: string, data: UserRequestType) => {
    return await clientAxios.patch<UserRequestType, AxiosResponse>(`/api/v1/user/${id}/update`, data);
}

export const changePassword = async (id: string, data: UserChangePasswordFormType): Promise<AxiosResponse> => {
    return await clientAxios.patch<UserChangePasswordFormType, AxiosResponse>(`/api/v1/user/${id}/change-password`, data);
}

