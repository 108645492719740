import {ClientFilterRequestType, ClientFilterType, ClientRequestType, ClientType} from "@client/types/client.types";

export const mapClientToRequestType = (client: ClientType): ClientRequestType => {
    return {
        firstName: client.firstName ?? '',
        lastName: client.lastName ?? '',
        companyName: client.companyName ?? '',
        status: client.status?.id ?? '',
        nip: client.nip ?? '',
        phone: client.phone ?? '',
        email: client.email ?? '',
        street: client.street ?? '',
        postCode: client.postCode ?? '',
        city: client.city ?? '',
    };
}

export const mapClientFilterRequestToClientFilterType =
    (clientFilterRequestType: ClientFilterRequestType)
        : ClientFilterType => {
        return {
            search: clientFilterRequestType?.search ?? '',
            page: clientFilterRequestType.page ?? 1,
        }
    }

