import React, {useEffect, useState} from 'react';
import {OfferForm} from "../../components";
import {OfferInitRequest, OfferRequestType, OfferType} from "../../types/offer.types";
import {useLocation} from "react-router-dom";
import {Maybe} from "@src/types/global.types";
import {createOffer, initOffer, updateOffer} from "@offer/utils/axios";
import {AxiosResponse} from "axios";

const OfferCreateView = () => {
    const searchParams = new URLSearchParams(useLocation().search);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [offer, setOffer] = useState<Maybe<OfferType>>();

    const initOfferForm = () => {
        setLoading(true);
        const offerInitRequest: OfferInitRequest  = {
            client: searchParams.get('client') ?? null
        };
        initOffer(offerInitRequest)
            .then((offer: OfferType) => setOffer(offer))
            .finally(() => setLoading(false));
    }

    useEffect(() => {initOfferForm() }, []);

    const saveOffer = async (data: OfferRequestType): Promise<AxiosResponse> => {
        return createOffer(data);
    }

    return (
        <>
            <h1>Nowa oferta</h1>

            <div>
                <h5 className="mb-3">Dane oferty</h5>
                <hr/>
                {isLoading && <>Loading...</>}
                {offer && <OfferForm offer={offer} saveOffer={saveOffer} />}
            </div>
        </>
    );

}

export default OfferCreateView;