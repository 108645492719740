import React, {useEffect} from "react";
import {Table} from "react-bootstrap";
import {ProcessFileType, ProcessType} from "@process/types/process.types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRefresh} from "@fortawesome/pro-regular-svg-icons";
import {ProcessFilesUpload} from "@process/components";
import {fetchProcessFiles} from "@process/utils/axios";
import {toast} from "react-toastify";
import {AxiosResponse} from "axios";
import ProcessFileComponent from "./ProcessFile.component";

interface ProcessFilesProps {
    process: ProcessType
}

const ProcessFilesComponent: React.FC<ProcessFilesProps> = ({process}) => {

    const [processFiles, setProcessFiles] = React.useState<ProcessFileType[]>([]);

    const fetchList = () => {
        fetchProcessFiles(process.id)
            .then((response: AxiosResponse<ProcessFileType[]>) => {
                setProcessFiles(response.data)
            })
            .catch(error => {
                toast.error("Wystąpił błąd :/");
                console.log('Error', error.message);
            })
    }

    useEffect(() => {
        fetchList();
    }, []);

    return (
        <div>
            <ProcessFilesUpload process={process} refresh={fetchList} />

            <Table hover bordered size="sm">
                <thead>
                    <tr>
                        <th>Nazwa pliku</th>
                        <th className="text-center">Kategoria</th>
                        <th>Dodany przez</th>
                        <th className="text-center">Data</th>
                        <th className="text-center">Typ</th>
                        <th className="text-end">Rozmiar</th>
                        <th className="text-center" style={{width: '0'}}>
                            <FontAwesomeIcon icon={faRefresh} fixedWidth={true} onClick={fetchList} />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {processFiles.map((processFile: ProcessFileType) => (
                        <ProcessFileComponent process={process} processFile={processFile} refresh={fetchList} key={processFile.id} />
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export default ProcessFilesComponent;