import React from "react";
import {EstimateItemFormComponent} from "@estimate/components";
import {EstimateType} from "@estimate/types/estimate.types";
import {createEstimateItem} from "@estimate/utils/axios";
import {AxiosResponse} from "axios";
import ReactModal from "react-modal";
import {EstimateItemRequestType, EstimateItemType} from "@estimate/types/estimateItem.types";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/pro-regular-svg-icons";

interface EstimateCreateProps {
    estimate: EstimateType,
    afterSuccessSave: Function,
}

const estimateItemDefault: EstimateItemType = {
    quantity: 1
}

const EstimateItemCreateComponent: React.FC<EstimateCreateProps> = ({estimate, afterSuccessSave}) => {

    const [showEstimateItemForm, setShowEstimateItemForm] = React.useState<boolean>(false);

    const showEstimateItemFormHandler = (e: React.MouseEvent<HTMLElement>): void => {
        e.preventDefault();
        setShowEstimateItemForm(prevState => !prevState);
    }

    const onAfterSuccessSaveHandler = (): void => {
        setShowEstimateItemForm(false);
        afterSuccessSave();
    }

    const saveEstimateItem = async (data: EstimateItemRequestType): Promise<AxiosResponse> => {
        return createEstimateItem(estimate.id, data);
    }

    return (
        <>
            <Button
                onClick={showEstimateItemFormHandler}
                variant={"outline-primary"}
            >+ dodaj własną pozycję</Button>

            {showEstimateItemForm && (
                <ReactModal
                    isOpen={showEstimateItemForm}
                    onRequestClose={showEstimateItemFormHandler}
                    overlayClassName="overlay overlay-dark"
                    className="overlay-wrap"
                    ariaHideApp={false}
                >
                    <div className="overlay-header">
                        <h3 className="overlay-header-title">Dodanie pozycji kosztorysu</h3>
                    </div>

                    <Button className="overlay-close" onClick={showEstimateItemFormHandler}>
                        <FontAwesomeIcon icon={faXmark} fixedWidth={true}/>
                    </Button>
                    <div className="overlay-body">
                        <EstimateItemFormComponent
                            estimateItem={{...estimateItemDefault}}
                            saveEstimateItem={saveEstimateItem}
                            afterSuccessSave={onAfterSuccessSaveHandler}
                        />
                    </div>
                </ReactModal>
            )}
        </>
    );
}

export default EstimateItemCreateComponent;