import {CommonErrorType, Maybe, Money} from "@src/types/global.types";
import {DictionaryType} from "@dictionary/types/dictionary.types";
import {UserType} from "@user/types/user.types";
import {ClientType} from "@client/types/client.types";
import {DepartmentType} from "@department/types/department.types";

export enum OfferDiscountTypes {
    Percent = 'percent',
    Amount = 'amount',
}

export interface ProductSimply {
    readonly id: string;
    readonly name: string;
    readonly code: string;
    readonly priceUnit: number;
    readonly priceUnitRegular: number;
};

export interface OfferItemForm {
    id: string|null,
    product: ProductSimply|null,
    // discountType: OfferDiscountTypes,
    // discountType: OfferItemDiscountTypeEnum,
    discountType: Maybe<string>,
    discountPercent?: number,
    discountAmount?: number,
    quantity?: number,
}

export interface OfferClientFormType {
    id: string,
    name: string,
}

export type OfferInitRequest = {
    client: Maybe<string>
}

export type OfferType = {
    id: Maybe<string>,
    createdBy: Maybe<UserType>,
    updatedBy: Maybe<UserType>,
    archivedBy: Maybe<UserType>,
    department: Maybe<DepartmentType>,
    status: Maybe<DictionaryType>,
    client: Maybe<ClientType>,
    createdAt: Maybe<string>,
    updatedAt: Maybe<string>,
    archivedAt: Maybe<string>,
    number: Maybe<string>,
    title: Maybe<string>,
    termDate: Maybe<string>,
    price: Maybe<Money>,
    priceRegular: Maybe<Money>,
    priceDiscount: Maybe<Money>,
    percentDiscount: Maybe<Money>,
    description: Maybe<string>,
    items: Maybe<OfferItemType[]>
}

export type OfferItemType = {
    id: Maybe<string>,
    createdAt: Maybe<string>,
    updatedAt: Maybe<string>,
    name: Maybe<string>,
    code: Maybe<string>,
    position: Maybe<number>,
    quantity: Maybe<number>,
    discountType: Maybe<string>,
    discountValue: Maybe<number>,
    priceUnit: Maybe<Money>,
    priceUnitDiscount: Maybe<Money>,
    priceUnitRegular: Maybe<Money>,
    priceTotal: Maybe<Money>,
    priceTotalDiscount: Maybe<Money>,
    priceTotalRegular: Maybe<Money>,
    percentDiscount: Maybe<number>,
    location: Maybe<string>,
    image: Maybe<string>,
    externalId: Maybe<string>,
}

export interface OfferRequestType {
    id: string|null,
    title: string,
    number: string,
    department: string|null,
    status: string|null,
    termDate: string|null,
    client: string|null,
    description: string,
}

export interface DiscountFormType{
    discount: string
}

export class DiscountFormModel<DiscountFormType> {
    discount: ''
}

export interface DiscountFormErrorType{
    discount?: CommonErrorType[]
}

export class DiscountFormErrorModel<DiscountFormErrorType> {
    discount: []
}

export type OfferFilterRequestType = {
    page?: Maybe<number>;
    sort?: Maybe<string[]>,
    search?: Maybe<string>;
    department?: Maybe<string>,
    status?: Maybe<string>,
    createdBy?: Maybe<string>,
    client?: Maybe<string>
}

export type OfferFilterType = {
    page?: Maybe<number>;
    sort?: Maybe<string[]>,
    search?: Maybe<string>;
    department?: Maybe<DepartmentType>,
    status?: Maybe<DictionaryType>,
    createdBy?: Maybe<UserType>,
    client?: Maybe<ClientType>
}
