import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import {ClientCard, ClientDropdownOptions} from "@client/components";
import {faPlus} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Col, Row} from "react-bootstrap";
import {ClientType} from "@client/types/client.types";
import {getClient} from "@client/utils/axios";
import {Maybe} from "@src/types/global.types";

const ClientCardView = () => {
    const { id } = useParams();

    const [client, setClient] = useState<Maybe<ClientType>>();

    useEffect(() => {
        getClient(id).then((client: ClientType) => setClient(client));
    }, []);

    return (
        <>
            <h1>Klient</h1>

            { client && (
                <>
                    <Row className="mb-3">
                        <Col xs="auto">
                            <Link to={`/offer/create?client=${client.id}`} className="btn btn-outline-primary">
                                <FontAwesomeIcon icon={faPlus} fixedWidth /> Dodaj ofertę
                            </Link>
                        </Col>
                        <Col xs="auto">
                            <ClientDropdownOptions client={client} />
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={6}>
                            <ClientCard client={client}/>
                        </Col>
                    </Row>
                </>
            )}
        </>
    );

}

export default ClientCardView;