import React, {useEffect, useState} from "react";
import {Maybe, PaginationDataType} from "@src/types/global.types";
import Select from "react-select";
import {getProductCollection} from "@product/utils/axios";
import {ProductFilterType, ProductType} from "@product/types/product.types";
import {OnChangeValue} from "react-select/dist/declarations/src/types";

interface FormProductSelectProps {
    onChange: Function,
    value?: Maybe<ProductType>,
    name?: string,
    className?: Maybe<string>,
    isClearable?: Maybe<boolean>,
    placeholder?: Maybe<string>,
}

const ProductSelectComponent: React.FC<FormProductSelectProps> = (
    {onChange, value: initProduct, name, className, isClearable, placeholder}
) => {
    const [productList, setProductList] = useState<ProductType[]>([]);
    const [selectedProduct, setSelectedProduct] = useState<Maybe<ProductType>>(initProduct);
    const [searchValue, setSearchValue] = useState<string>('');
    const [isLoading, setLoading] = useState<boolean>(false);

    const fetchProducts = () => {
        const filter: ProductFilterType = {search: searchValue}
        setLoading(true);
        getProductCollection(filter)
            .then((response: PaginationDataType<ProductType>) => setProductList(response.items))
            .finally(() => setLoading(false))
        ;
    }

    useEffect(() => {
        const timer = setTimeout(fetchProducts, 250);
        return () => clearTimeout(timer);
    }, [searchValue]);


    const fetchSelectedProduct = () => {
        if (!(initProduct?.id ?? null)) {
            setSelectedProduct(null);
            return;
        }

        if (initProduct && selectedProduct && initProduct.id === selectedProduct.id) {
            const selectedProductOK = Object.keys(selectedProduct).length > 1;
            if (selectedProductOK) {
                return;
            }
        }

        const initProductOK =  Object.keys(initProduct).length > 1;
        if (initProductOK) {
            setSelectedProduct(initProduct);
            return;
        }

        const findProduct: ProductType = productList.find((user: ProductType) => user.id === initProduct.id) ?? null;
        if (findProduct) {
            setSelectedProduct(findProduct);
            return;
        }

        // setLoading(true);
        // getClient(initProduct.id)
        //     .then((responseProduct: ProductType) => setSelectedProduct(responseProduct))
        //     .finally(() => setLoading(false))
    }


    const onChangeHandler = (product: OnChangeValue<ProductType, false>) => {
        setSelectedProduct(product);
        onChange(product, name);
    }

    useEffect(fetchSelectedProduct, [initProduct]);

    return (
        <Select
            options={productList}
            isClearable={isClearable}
            name={name}
            value={selectedProduct}
            className={'p-0 form-select ' + (className ? ` ${className}` : '')}

            getOptionLabel={(product: ProductType) => `[${product.sku}] ${product.name}`}
            getOptionValue={(product: ProductType) => product.id}

            onChange={onChangeHandler}
            isLoading={isLoading}
            inputValue={searchValue}
            onInputChange={value => setSearchValue(value)}
            placeholder={placeholder}
            noOptionsMessage={() => "Nie znaleziono produktu"}
            styles={{
                control: (baseStyles) => ({...baseStyles, width: 'auto', border: '0', minHeight: '0'}),
                dropdownIndicator: (baseStyles) => ({...baseStyles, padding: '0 8px'}),
                clearIndicator: (baseStyles) => ({...baseStyles, padding: '6px 8px'}),
            }}
        />
    )
}

export default ProductSelectComponent;