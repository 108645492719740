import React, {useEffect} from "react";
import {Col, Row} from "react-bootstrap";
import {Login} from "@auth/components";
import {Link, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {auth, checkLogin} from "@auth/store/authSlice";
import {AppDispatch} from "@src/store";

const LoginView:React.FC = () => {
    const authData = useSelector(auth)
    const locationParams = new URLSearchParams(useLocation().search)
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        let token = null;
        if (locationParams.has('token')) {
            token = locationParams.get('token');
            dispatch(checkLogin(token));
        } else {
            token = window.localStorage.getItem('auth.token')
            if (token) {
                dispatch(checkLogin(token));
            }
        };
    }, [])

    if (true === authData.isCheckLogin) {
        return (
            <div className="container">
                <div className="d-flex justify-content-center align-items-center" style={{height: '200px'}}>
                    <h2>Logowanie...</h2>
                </div>
            </div>
        );
    }

    return (
        <div className="container flex-grow view-recover-password">
            <Row className="justify-content-center d-flex align-items-center" style={{height: '100vh'}}>
                <Col className="col-5 border col-auto pb-3">
                    <h1>Logowanie</h1>
                    <hr/>
                    <Login/>
                    <hr/>
                    <Link to="/recover-password">
                        odzyskaj hasło &raquo;
                    </Link>
                </Col>
            </Row>
        </div>
    );
};

export default LoginView;