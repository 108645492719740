import React, {ReactElement, ReactNode} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import {useLocation, useNavigate} from "react-router-dom";
import {objectToUrl, parseFilterParamsFromUrl} from "@src/helpers/utils.helper";
import {Maybe} from "@src/types/global.types";

interface FilterProps{
    children: ReactNode,
    getFilterParams?: Maybe<Function>
}

const FilterComponent:React.FC<FilterProps> = ({children, getFilterParams}): ReactElement => {
    const navigate = useNavigate();
    const locationParams = useLocation().search;

    const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget)

        let filterParams: any = {};

        if (getFilterParams) {
            filterParams = getFilterParams();
        }

        const searchParams = parseFilterParamsFromUrl(locationParams);
        if (typeof searchParams.sort !== "undefined") {
            filterParams.sort = searchParams.sort;
        }

        formData.forEach((value, key) => {
            if (value) { filterParams[key] = value }
        });

        let filterUrl = '';
        if (Object.values(filterParams).length > 0) {
            filterUrl += '?' + objectToUrl(filterParams).split('&').filter(Boolean).join('&');
        }

        navigate(filterUrl);
    }

    return (
        <Form className="mb-3" onSubmit={onSubmitHandler} method="get">
            <Row>
                {children}

                <Col xs="auto">
                    <Button type="submit">
                        Szukaj
                    </Button>
                </Col>
                <Col xs="auto">
                    <Button onClick={() => navigate('')} variant="outline-primary" >
                        Wyczyść
                    </Button>
                </Col>
            </Row>
        </Form>
    );
}


export default FilterComponent;