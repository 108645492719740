import React, {useState} from "react";
import {
    DiscountFormErrorModel,
    DiscountFormErrorType,
    DiscountFormModel,
    DiscountFormType
} from "../../types/offer.types";
import {client as clientAxios} from "../../../../library/axios.library";
import {toast} from "react-toastify";

interface OfferDiscountFormProps{
    id: string,
    refetch: Function
}

const OfferDiscountFormComponent:React.FC<OfferDiscountFormProps> = ({id, refetch}) => {
    const [isSending, setSending] = useState<boolean>(false);
    const [discountFormData, setDiscountFormData] = useState<DiscountFormType>(new DiscountFormModel());
    const [discountFormErrors, setDiscountFormErrors]
        = useState<DiscountFormErrorType>(new DiscountFormErrorModel());

    const onSubmitHandler = e => {
        e.preventDefault();
        setSending(true);

        clientAxios.post(`/api/v1/offer/${id}/discount`, discountFormData)
            .then(response => {
                refetch();
                setDiscountFormErrors(new DiscountFormErrorModel());
                toast.success("Zapisano");
            })
            .catch(error => {
                if (400 === error.response.status) {
                    const responseErrors = error.response?.data?.errors || {};
                    setDiscountFormErrors(responseErrors);
                } else {
                    console.log('Error', error.message);
                }
            }).finally(() => {
            setSending(false);
        })
    }

    return (
        <div className="mb-3">
            <form
                className="row row-cols-lg-auto align-items-start justify-content-end"
                onSubmit={onSubmitHandler}
            >
                <div className="col-form-label">
                    Zastosuj rabat
                </div>
                <div className="col" style={{width: '150px'}}>
                    <div className="input-group">
                        <input type="text"
                               className={"form-control " + (discountFormErrors.discount ? ' is-invalid' : '')}
                               value={discountFormData.discount}
                               onChange={e => {
                                   setDiscountFormData(prevState => {
                                       return {...prevState, discount: e.target.value}
                                   })
                               }}
                        />
                        <div className="input-group-text">%</div>
                    </div>
                </div>
                <div className="col">
                    <button type="submit" className="btn btn-outline-primary">Zastosuj</button>
                </div>
            </form>
            {discountFormErrors.discount && (
                <div className="text-danger text-end">
                    {discountFormErrors.discount.map(({message}, key) => <div
                        key={key}>{message}</div>)}
                </div>
            )}
        </div>
    );
}

export default OfferDiscountFormComponent;