import {client as clientAxios} from "@src/library/axios.library";
import {AxiosResponse} from "axios";
import {DictionaryFilterType, DictionaryType} from "@dictionary/types/dictionary.types";

// export const getDictionary = async (id: string): Promise<UserType> => {
//     const {data} = await clientAxios.get<null, AxiosResponse<UserType>>(`/api/v1/user/${id}`);
//     return data;
// }

export const getDictionaryCollection = async (filter: DictionaryFilterType): Promise<DictionaryType[]> => {
    const {data} = await clientAxios.post<DictionaryFilterType, AxiosResponse<DictionaryType[]>>('/api/v1/dictionary', filter);
    return data;
}

