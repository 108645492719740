import React, {useEffect, useState} from "react";
import {Form} from "react-bootstrap";
import {Maybe} from "@src/types/global.types";
import {getDepartmentCollection} from "@src/modules/department/utils/axios";
import {DepartmentType} from "@src/modules/department/types/department.types";

interface DepartmentSelectProps {
    onChange: Function,
    value?: Maybe<DepartmentType>,
    name?: Maybe<string>,
    className?: Maybe<string>,
    isInvalid?: Maybe<boolean>,
    isClearable?: Maybe<boolean>
    placeholder?: Maybe<string>,
}

const DepartmentSelectComponent: React.FC<DepartmentSelectProps> = (
    {onChange, value, name, className, isInvalid, placeholder}:DepartmentSelectProps
) => {
    const [departmentList, setDepartmentList] = useState<Maybe<DepartmentType[]>>();

    const fetchCollection = () => {
        getDepartmentCollection()
            .then((response: DepartmentType[]) => {
                setDepartmentList(response)
            })
    }

    const onChangeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value: string = e.target.value;
        const selectedDepartment: Maybe<DepartmentType> = departmentList.find((department:DepartmentType) => {
            return department.id === value;
        }) ?? null;

        onChange(selectedDepartment, name);
    }

    useEffect(() => {
        fetchCollection();
    }, [])

    return (
        <Form.Select
            value={value?.id ?? ""}
            name={name}
            onChange={onChangeHandler}
            className={(className ? ` ${className}` : '')}
            isInvalid={isInvalid}
        >
            <option value="">{placeholder ?? `- wybierz oddział -`}</option>
            {departmentList && departmentList.map((department: DepartmentType) => (
                <option value={department.id} key={department.id}>
                    {department.code ? department.code + ' - ' : ''}{department.name}
                </option>
            ))}
        </Form.Select>
    );
}

export default DepartmentSelectComponent;
