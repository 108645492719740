import React, {ReactElement} from "react";
import {ProcessItemType, ProcessItemPartType, ProcessType} from "@process/types/process.types";
import {Alert, Button, ButtonGroup, Dropdown} from "react-bootstrap";
import {
    createProcessFileEntry,
    downloadProcessFileEntry,
    initProcessItemPart,
    updateProcessFileEntry
} from "@process/utils/axios";
import {toast} from "react-toastify";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsis, faArrowsRotate, faSpinner} from "@fortawesome/pro-light-svg-icons";
import Table from "react-bootstrap/Table";
import {ProcessItemPart as ProcessItemPartComponent} from "..";
import {faDownload} from "@fortawesome/pro-regular-svg-icons";
import {downloadFile} from "@src/helpers/utils.helper";
import {AxiosResponse} from "axios";

interface ProcessItemProps {
    processItem: ProcessItemType;
    process: ProcessType;
    refresh: Function;
}

const ProcessItemComponent: React.FC<ProcessItemProps> = ({processItem, process, refresh}): ReactElement => {

    const [initParts, setInitParts] = React.useState<boolean>(false);
    const [isCreatingFileEntry, setCreatingFileEntry] = React.useState<boolean>(false);
    const [isUpdatingFileEntry, setUpdatingFileEntry] = React.useState<boolean>(false);
    const [isDownloadingFileEntry, setDownloadingFileEntry] = React.useState<boolean>(false);

    const initProcessItemPartHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setInitParts(true);

        initProcessItemPart(process.id, processItem.id).then(() => {
            toast.success("Zapisano");
            refresh();
        })
            .catch(error => {
                toast.error("Wystąpił błąd :/");
                console.log('Error', error.message);
                setInitParts(false);
            })
    }


    const createProcessFileEntryHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (isCreatingFileEntry) {
            return;
        }
        setCreatingFileEntry(true);

        createProcessFileEntry(process.id)
            .then(() => {
                toast.success("Zapisano");
                refresh();
            })
            .finally(() => {
                setCreatingFileEntry(false);
            })
            .catch(error => {
                toast.error("Wystąpił błąd :/");
                console.log('Error', error.message);
            })
    }

    const downloadProcessFileEntryHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (isDownloadingFileEntry) {
            return;
        }
        setDownloadingFileEntry(true);

        downloadProcessFileEntry(process.id)
            .then((response: AxiosResponse<Blob>) => {
                const fileName = response.headers["content-disposition"]?.split(";")[1]?.replace(/filename=/g, "")
                    ?? process.fileEntry.file.filename;

                downloadFile(response.data, fileName);
            })
            .finally(() => {
                setDownloadingFileEntry(false);
            })
    }

    const updateProcessFileEntryHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (isUpdatingFileEntry) {
            return;
        }
        setUpdatingFileEntry(true);

        updateProcessFileEntry(process.id)
            .then(() => {
                toast.success("Zapisano");
                refresh();
            })
            .finally(() => {
                setUpdatingFileEntry(false);
            })
            .catch(error => {
                toast.error("Wystąpił błąd :/");
                console.log('Error', error.message);
            })
    }

    return (
        <div>

            <dl className="dl-horizontal">
                <dt>Protokół przyjęcia:</dt>
                <dd>
                    {process.fileEntry ? (
                        <div>
                            <ButtonGroup size={"sm"}>
                                <Button size={"sm"} variant={"outline-primary"}
                                        onClick={downloadProcessFileEntryHandler}
                                        disabled={isDownloadingFileEntry}
                                >
                                    {isDownloadingFileEntry ? (
                                        <><FontAwesomeIcon icon={faSpinner} fixedWidth={true}/> pobieram protokół...</>
                                    ) : (
                                        <><FontAwesomeIcon icon={faDownload} fixedWidth={true}/> pobierz protokół</>
                                    )}

                                </Button>

                                <Dropdown as={ButtonGroup}>
                                    <Dropdown.Toggle variant="outline-primary" size={"sm"}>
                                        <FontAwesomeIcon icon={faEllipsis}/>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            disabled={isUpdatingFileEntry}
                                            onClick={updateProcessFileEntryHandler}
                                        >
                                            <FontAwesomeIcon icon={faArrowsRotate} fixedWidth/> Aktualizuj protokół
                                        </Dropdown.Item>
                                    </Dropdown.Menu>

                                </Dropdown>
                            </ButtonGroup>
                            &nbsp;
                            <em className="text-muted small">
                                Data zapisu: {process.fileEntry.file.updatedAt}
                            </em>
                        </div>
                    ) : (
                        <Button size={"sm"} variant={"outline-primary"}
                                onClick={createProcessFileEntryHandler}
                        >
                            {isCreatingFileEntry ? (
                                <><FontAwesomeIcon icon={faSpinner} fixedWidth={true}/> generuję protokół...</>
                            ) : (
                                <><FontAwesomeIcon icon={faDownload} fixedWidth={true}/> generuj protokół przyjęcia</>
                            )}
                        </Button>
                    )}
                </dd>
            </dl>
            <dl className="dl-horizontal">
                <dt>Nazwa:</dt>
                <dd>{processItem.name}</dd>
            </dl>
            {processItem.brand && (
                <dl className="dl-horizontal">
                    <dt>Marka:</dt>
                    <dd>{processItem.brand}</dd>
                </dl>
            )}
            {processItem.model && (
                <dl className="dl-horizontal">
                    <dt>Model:</dt>
                    <dd>{processItem.model}</dd>
                </dl>
            )}
            <dl className="dl-horizontal">
                <dt>Number seryjny:</dt>
                <dd>{processItem.serialNumber}</dd>
            </dl>
            <dl className="dl-horizontal border-0">
                <dt>Opis ustarki:</dt>
                <dd>
                    {!processItem.note ? (
                        <Alert className="alert-warning">
                            Nie podano opisu usterki
                        </Alert>
                    ) : (
                        <em style={{whiteSpace: "pre-wrap"}}>{processItem.note}</em>
                    )}
                </dd>
            </dl>

            <div className="mt-3">
                <p className="lead">Lista elementów</p>

                {processItem.parts.length == 0 ? (
                    <Alert variant="warning">
                        Lista elementów nie została jeszcze dodana.&nbsp;
                        <Button variant="outline-primary" onClick={initProcessItemPartHandler}>
                            {initParts ? (
                                <><FontAwesomeIcon icon={faSpinner} spin={true}/> Wysyłam...</>
                            ) : (
                                <>Dodaj domyślną listę &raquo;</>
                            )}
                        </Button>
                    </Alert>
                ) : (
                    <Table hover bordered size="sm">
                        <thead>
                        <tr>
                            <th style={{width: '30px'}}>Lp</th>
                            <th className="text-center" style={{width: '100px'}}>Czy dołączony</th>
                            <th style={{width: '250px'}}>Nazwa elementu</th>
                            <th>Opis</th>
                        </tr>
                        </thead>
                        <tbody>
                        {processItem.parts.map((processItemPart: ProcessItemPartType, index: number) => (
                            <ProcessItemPartComponent
                                index={index}
                                key={processItemPart.id}
                                processItemPart={processItemPart}
                                processId={process.id}
                                processItemId={processItem.id}
                            />
                        ))}
                        </tbody>
                    </Table>
                )}

            </div>

        </div>
    );

}

export default ProcessItemComponent;