import React from "react";
import {EstimateFormComponent} from "@estimate/components";
import {ProcessType} from "@process/types/process.types";
import { EstimateFormType} from "@estimate/types/estimate.types";
import {createEstimate} from "@estimate/utils/axios";
import {AxiosResponse} from "axios";

interface EstimateCreateProps {
    process: ProcessType,
    afterSuccessSave: Function,
}

const EstimateCreateComponent: React.FC<EstimateCreateProps> = ({process, afterSuccessSave}) => {

    const initEstimateForm: EstimateFormType = {
        process: process.id,
        name: ''
    }

    const saveEstimate = async (data: EstimateFormType): Promise<AxiosResponse> => {
        return createEstimate(data);
    }

    return (
        <>
            <EstimateFormComponent
                initEstimateForm={initEstimateForm}
                saveEstimate={saveEstimate}
                afterSuccessSave={afterSuccessSave}
            />
        </>
    );
}

export default EstimateCreateComponent;