import {
    ProcessFileFormType, ProcessFileType, ProcessFilterRequestType, ProcessFilterType,
    ProcessFormType,
    ProcessItemFormType,
    ProcessItemType,
    ProcessType
} from "@process/types/process.types";
import {useState} from "react";
import {Maybe} from "@src/types/global.types";
import {DictionaryType} from "@dictionary/types/dictionary.types";
import {UserType} from "@user/types/user.types";
import {DepartmentType} from "@department/types/department.types";

export const mapProcessToProcessFormType = (process: ProcessType): ProcessFormType => {

    let processItems: ProcessItemFormType[] = [];
    process.items.map((processItem: ProcessItemType) => {
        processItems.push({
            id: processItem.id,
            name: processItem.name,
            serialNumber: processItem.serialNumber,
            note: processItem.note,
        });
    })

    return {
        departmentAccount: process?.departmentAccount?.id ?? '',
        number: process.number ?? '',
        user: process?.user?.id ?? '',
        status: process?.status?.id ?? '',
        title: process.title ?? '',
        date: process.date ?? '',
        note: process.note ?? '',
        customer: {
            client: process?.customer?.client?.id ?? '',
            firstName: process?.customer?.firstName ?? '',
            lastName: process?.customer?.lastName ?? '',
            companyName: process?.customer?.companyName ?? '',
            email: process?.customer?.email ?? '',
            phone: process?.customer?.phone ?? '',
            taxNumber: process?.customer?.taxNumber ?? '',
            address: {
                street: process?.customer?.address?.street ?? '',
                postCode: process?.customer?.address?.postCode ?? '',
                city: process?.customer?.address?.city ?? '',
            }
        },
        shipment: {
            shipmentMethod: process?.shipment?.shipmentMethod?.id ?? ''
        },
        incomeShipment: {
            shipmentMethod: process?.incomeShipment?.shipmentMethod?.id ?? ''
        },
        items: processItems
    };
}

export const mapProcessFileToProcessFileFormType = (processFile: ProcessFileType): ProcessFileFormType => {
    return {
        name: processFile.file.name ?? '',
        category: processFile.category?.id ?? ''
    }
}

export const mapProcessFilterRequestToProcessFilterType =
    (processFilterRequestType: ProcessFilterRequestType)
: ProcessFilterType=> {
    return {
        search: processFilterRequestType?.search ?? '',
        page: processFilterRequestType.page ?? 1,
        order: [],
        status: {id: processFilterRequestType?.status ?? null},
        createdBy: {id: processFilterRequestType?.createdBy ?? null},
        user: {id: processFilterRequestType?.user ?? null},
        client: {id: processFilterRequestType?.client ?? null},
        departmentAccount: {id: processFilterRequestType?.departmentAccount ?? null},
    }
}
