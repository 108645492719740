import React, {ReactElement} from 'react';
import {Dropdown} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {downloadFile, getUuidFromId} from "@src/helpers/utils.helper";
import {toast} from "react-toastify";
import {cloneOffer, downloadOffer, sendOfferEmail} from "@offer/utils/axios";
import {cloneOfferSwal, sendOfferEmailSwal} from "@offer/utils/swal";
import {faEye, faPen, faClone, faTrash, faEnvelope, faFilePdf} from "@fortawesome/pro-light-svg-icons";
import {OfferType} from "@offer/types/offer.types";
import {Maybe} from "@src/graphql/$graphql";
import {faEllipsisVertical} from "@fortawesome/pro-regular-svg-icons";

interface OfferOptionsProps {
    offer: OfferType,
    buttonSize?: Maybe<'sm' | 'lg'>,
    fetchOffers: Function
}

const OfferDropdownOptionsComponent:React.FC<OfferOptionsProps> = ({offer, buttonSize, fetchOffers}): ReactElement => {

    const cloneHandler = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        cloneOfferSwal().then((result) => {
            if (result.isConfirmed) {
                cloneOffer(getUuidFromId(offer.id)).then(() => {
                    fetchOffers();
                    toast.success("Oferta została skopiowana");
                });
            }
        })
    }

    const downloadOfferFileHandler = () => {
        downloadOffer(getUuidFromId(offer.id)).then(response => {
            downloadFile(response.data, 'oferta.pdf')
        }).catch(error => {
            console.log('Error', error.message);
        })
    };

    const sendOfferToClientHandler = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        sendOfferEmailSwal().then((result) => {
            if (result.isConfirmed) {
                sendOfferEmail(getUuidFromId(offer.id)).then(() => {
                    toast.success("Oferta została wysłana");
                });
            }
        })
    }

    return (
        <Dropdown>
            <Dropdown.Toggle variant="light" size={buttonSize}>
                <FontAwesomeIcon icon={faEllipsisVertical} fixedWidth={true}/>
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item as={Link} to={'/offer/' + getUuidFromId(offer.id)}>
                    <FontAwesomeIcon icon={faEye} fixedWidth/> Karta</Dropdown.Item>
                <Dropdown.Item as={Link} to={"/offer/edit/" + getUuidFromId(offer.id)}>
                    <FontAwesomeIcon icon={faPen} fixedWidth/> Edycja
                </Dropdown.Item>

                <Dropdown.Item onClick={downloadOfferFileHandler} >
                    <FontAwesomeIcon icon={faFilePdf} fixedWidth={true}/> Pobierz PDF
                </Dropdown.Item>

                <Dropdown.Item onClick={cloneHandler} >
                    <FontAwesomeIcon icon={faClone} fixedWidth/> Kopiuj
                </Dropdown.Item>

                <Dropdown.Item onClick={sendOfferToClientHandler} >
                    <FontAwesomeIcon icon={faEnvelope} fixedWidth={true}/> Wyślij do klienta
                </Dropdown.Item>

                <Dropdown.Divider/>
                <Dropdown.Item as={Link} to={`/offer/delete/` + getUuidFromId(offer.id)} className="text-danger">
                    <FontAwesomeIcon icon={faTrash} fixedWidth/> Usuń
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default OfferDropdownOptionsComponent;