import React, {ReactElement, useEffect} from "react";
import FilterComponent from "@src/components/Filter/Filter.component";
import FilterSearch from "@src/components/Filter/components/FilterSearch/FilterSearch.component";
import {ClientFilterRequestType} from "@client/types/client.types";
import {Col, Row} from "react-bootstrap";
import {ProcessFilterType} from "@process/types/process.types";
import {mapClientFilterRequestToClientFilterType as mapClientFilter} from "@client/utils/mapper";

interface ClientFilterProps {
    clientFilterRequest: ClientFilterRequestType
}

const ClientFilterComponent:React.FC<ClientFilterProps> = ({clientFilterRequest}): ReactElement => {
    const [filter, setFilter] = React.useState<ProcessFilterType>(mapClientFilter(clientFilterRequest));
    useEffect(() => setFilter(mapClientFilter(clientFilterRequest)), [clientFilterRequest]);

    return (
        <FilterComponent>
            <Row className="pb-3">
                <Col xs="3">
                    <FilterSearch value={filter.search ?? ''} />
                </Col>
            </Row>
        </FilterComponent>
    );
}

export default ClientFilterComponent;