import React, {ReactElement} from 'react';
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortUp, faSortDown } from '@fortawesome/pro-duotone-svg-icons'
import { faSort } from '@fortawesome/pro-light-svg-icons'

interface SortableColumnProps{
    label: string,
    orderKey: string,
    params: any
}

const SortableColumnComponent:React.FC<SortableColumnProps> = ({label, orderKey, params}): ReactElement => {

    const urlParams = {...params};
    let sortParams: string[] = Object.values({...urlParams.sort} ?? {});
    delete urlParams.page;
    delete urlParams.sort;

    const sortIdx: number = sortParams.findIndex((sort: string) => sort.replace('-', '') === orderKey);
    const sortExists: boolean = sortIdx >= 0;
    const isDescSort: boolean = sortExists && sortParams[sortIdx].slice(0, 1) === '-';

    if (sortExists) {
        sortParams[sortIdx] = isDescSort ? orderKey : '-'+orderKey;
    } else {
        sortParams = [orderKey];
    }

    const linkParams: any = {...urlParams};
    sortParams.map((value: string, index: number) => {
        linkParams['sort['+index.toString()+']'] = value;
    })

    const sortLink: string = Object.values(linkParams).length > 0
        ? '?' + (new URLSearchParams(linkParams).toString())
        : '';

    return (
        <Link to={sortLink} className={"text-nowrap "+(sortExists ? 'is-sorted' : '')}>
            {label}
            {sortExists
                ? <FontAwesomeIcon icon={isDescSort ? faSortDown : faSortUp} fixedWidth={true} />
                : <FontAwesomeIcon icon={faSort}  fixedWidth={true} />
            }
        </Link>
    );
}

 export default SortableColumnComponent;