import React from "react";
import {Col, Row} from "react-bootstrap";
import {RecoverPassword} from "@auth/components";
import {Link} from "react-router-dom";

const RecoverPasswordView:React.FC = () => {
    return (
        <div className="container flex-grow view-recover-password">
            <Row className="justify-content-center d-flex align-items-center" style={{height: '100vh'}}>
                <Col className="col-5 border col-auto pb-3">
                    <h1>Odzyskanie hasła</h1>
                    <p>
                        Jeśli na ten e-mail jest założone konto, to wyślemy na niego wiadomość.
                    </p>
                    <hr/>
                    <RecoverPassword/>
                    <hr/>

                    <Link to="/">
                        &laquo; wróć do logowania
                    </Link>
                </Col>
            </Row>
        </div>
    );
};

export default RecoverPasswordView;