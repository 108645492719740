import React, {useEffect, useState} from 'react';
import {OfferForm} from "@offer/components";
import {OfferRequestType, OfferType} from "@offer/types/offer.types";
import {useParams} from "react-router-dom";
import {Maybe} from "@src/types/global.types";
import {getOffer, updateOffer} from "@offer/utils/axios";
import {AxiosResponse} from "axios";

const OfferEditView = () => {
    const { id: offerId } = useParams();
    const [offer, setOffer] = useState<Maybe<OfferType>>();

    useEffect(() => {
        getOffer(offerId).then((offer: OfferType) => setOffer(offer))
    }, []);

    const saveOffer = async (data: OfferRequestType): Promise<AxiosResponse> => {
        return updateOffer(offer.id, data);
    }

    return (
        <>
            <h1 className="text-center">Edycja oferty</h1>
            <div className="p-3">
                <h5 className="mb-3">Dane oferty</h5>
                <hr/>
                {offer && <OfferForm offer={offer} saveOffer={saveOffer} />}
            </div>
        </>
    );
}

export default OfferEditView;