import {UserRequestType, UserType} from "@user/types/user.types";

export const mapUserToRequestType = (user: UserType): UserRequestType => {
    return {
        email: user.email,
        name: user.name,
        surname: user.surname,
        phone: user.phone,
        jobPosition: user.jobPosition,
        department: user?.department?.id ?? null,
    };
}