import React from 'react';
import Swal from "sweetalert2";
import {client as clientAxios} from "@src/library/axios.library";
import {toast} from "react-toastify";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faXmark } from '@fortawesome/pro-regular-svg-icons'
import {NumberFormat} from "@src/components";
import {OfferItemType} from "@offer/types/offer.types";

interface OfferItemProps {
    offerItem: OfferItemType,
    index: number,
    refetch: Function,
    showOfferItemFormHandler: any,
}

const OfferItemComponent:React.FC<OfferItemProps> =
    ({offerItem, index, refetch, showOfferItemFormHandler}) =>
{
    const removeItemHandler = e =>{
        e.preventDefault();

        Swal.fire({
            title: 'Czy usunąć prozycję?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            confirmButtonText: 'TAK, usuń!',
            cancelButtonText: 'Anuluj',
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                try{
                    await clientAxios.delete(`/api/v1/offer/item/${offerItem.id}/delete`);
                } catch (error: any) {
                    if (axios.isAxiosError(error)) {
                        console.log(error.response)
                    }

                    toast.error("Błąd!");
                    return;
                }

                refetch();
                toast.success("Usunięto");
            }
        })
    }

    return (
        <tr>
            <td>{index + 1}</td>
            <td>
                <div className="row">
                    {offerItem.image && (
                        <div className="col-auto">
                            <a href={offerItem.location} target="_blank">
                                <img src={offerItem.image} style={{maxWidth: '80px', maxHeight: '80px'}}/>
                            </a>
                        </div>
                    )}
                    <a
                        href="#"
                        className="col"
                        onClick={(e) => {
                            e.preventDefault();
                            showOfferItemFormHandler(true, offerItem)
                        }}
                    >
                        <div>{offerItem.name}</div>
                        <div className="text-muted">{offerItem.code}</div>
                    </a>
                </div>
            </td>
            <td className="text-end">
                <NumberFormat value={offerItem.priceUnitRegular} />
            </td>
            <td className="text-end">
                <NumberFormat value={offerItem.priceUnitDiscount} />
            </td>
            <td className="text-end">
                <NumberFormat value={offerItem.percentDiscount} extension="%" />
            </td>
            <td className="text-end">
                <NumberFormat value={offerItem.priceUnit} />
            </td>
            <td className="text-end">
                {offerItem.quantity}
            </td>
            <td className="text-end">
                <NumberFormat value={offerItem.priceTotal} />
            </td>
            <td className="text-end">
                <div className="btn-group">
                    <button className="btn btn-outline-primary btn-sm"
                            onClick={() => showOfferItemFormHandler(true, offerItem)}
                    >
                        <FontAwesomeIcon icon={faPen} fixedWidth={true}/>
                    </button>
                    &nbsp;
                    <button className="btn btn-outline-danger btn-sm"
                            onClick={removeItemHandler}
                    >
                        <FontAwesomeIcon icon={faXmark} fixedWidth={true}/>
                    </button>
                </div>

            </td>
        </tr>
    );
}

export default OfferItemComponent;