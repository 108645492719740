import React, {FormEventHandler, ReactElement, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinnerThird} from "@fortawesome/pro-regular-svg-icons";
import {ProcessType, ProcessErrorType, ProcessItemFormType} from "@process/types/process.types";
import {Button, Col, Form, Row} from "react-bootstrap";
import {UserSelect} from "@src/modules/user/components";
import {ClientSelect} from "@src/modules/client/components";
import {DepartmentSelect} from "@src/modules/department/components";
import {initProcess} from "../../utils/axios";
import {DictionarySelect} from "@dictionary/components";
import {Maybe} from "@src/types/global.types";
import {ShipmentMethodSelect} from "@src/modules/shipment/components";
import {ShipmentMethodType, ShipmentMethodTypeEnum} from "@src/modules/shipment/types/shipmentMethod.types";
import {UserType} from "@user/types/user.types";
import {ClientType} from "@client/types/client.types";
import {toast} from "react-toastify";
import {mapProcessToProcessFormType} from "@process/types/mapper";
import {useNavigate} from "react-router-dom";
import {FormError} from "@src/components";
import {DepartmentType} from "@department/types/department.types";
import {DictionaryType} from "@dictionary/types/dictionary.types";


interface ProcessFormProps {
    process: ProcessType,
    saveProcess: Function
}

const ProcessFormComponent: React.FC<ProcessFormProps> = (
    {process: processInit, saveProcess}
): ReactElement => {
    const navigate = useNavigate();
    const [process, setProcess] = useState<ProcessType>(processInit);
    const [formErrors, setFormErrors] = useState<ProcessErrorType>({});
    const [isSending, setSending] = useState<boolean>(false)

    const removeFromFormErrors = (key: string) => {
        setFormErrors(prevState => {
            let newState = {...prevState};
            delete newState[key];
            return newState;
        })
    }

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name;
        const value = e.target.value;

        setProcess(prevState => ({...prevState, [name]: value}));
        removeFromFormErrors(name);
    }

    const onStatusChangeHandler = (status: Maybe<DictionaryType>) => {
        removeFromFormErrors('status');
        setProcess(prevState => ({...prevState, status: status}));
    }

    const onShipmentMethodChangeHandler = (shipmentMethod: Maybe<ShipmentMethodType>) => {
        removeFromFormErrors('shipment');
        setProcess(prevState => ({...prevState, shipment: {...prevState.shipment, shipmentMethod}}));
    }

    const onIncomeShipmentMethodChangeHandler = (shipmentMethod: Maybe<ShipmentMethodType>) => {
        removeFromFormErrors('incomeShipment');
        setProcess(prevState => ({...prevState, incomeShipment: {...prevState.incomeShipment, shipmentMethod}}));
    }

    const onDepartmentChangeHandler = (department?: Maybe<DepartmentType>) => {
        setProcess(prevState => {
            return {...prevState, departmentAccount: department}
        })
        removeFromFormErrors('departmentAccount');
        initProcess({departmentAccount: department?.id ?? null}).then((process: ProcessType) => {
            setProcess(prevState => {
                return {...prevState, number: process.number ?? ''}
            });
        });
    }

    const onProcessUserChangeHandler = (user: UserType) => {
        setProcess((prevState: ProcessType) => {
            return {...prevState, user: user}
        });
    }

    const onClientChangeHandler = (client?: Maybe<ClientType>) => {
        removeFromFormErrors('client');

        setProcess((prevState:ProcessType) => {
            return {...prevState, customer: {...prevState.customer ?? {}, client: client}}
        });

        if (client) {
            initProcess({client: client.id}).then((process: ProcessType) => {
                setProcess(prevState => {
                    return {...prevState, customer: process.customer}
                });
            });
        }
    }

    const onCustomerChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name;
        const value = e.target.value;

        setProcess(prevState => (
            {...prevState, customer: {...prevState.customer, [name]: value}}
        ));
        removeFromFormErrors(name);
    }

    const onCustomerAddressChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name;
        const value = e.target.value;

        setProcess((prevState: ProcessType) => ({
            ...prevState,
            customer: {
                ...prevState.customer,
                address: {...prevState.customer.address, [name]: value}
            }
        }));
        removeFromFormErrors(name);
    }

    const onItemsChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name;
        const value = e.target.value;

        setProcess((prevState: ProcessType): ProcessType => {
            const processItem: ProcessItemFormType = {...prevState.items[0], [name]: value};
            return {...prevState, items: [processItem]};
        });

        removeFromFormErrors(name);
    }

    const onSubmitHandler: FormEventHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setSending(true);

        saveProcess(mapProcessToProcessFormType(process))
            .then(response => {
                toast.success("Proces został zapisany");
                navigate(`/process/${response.data?.id ?? process.id}`);
            })
            .catch(error => {
                if (400 === error.response.status) {
                    const responseErrors = error.response?.data?.errors || {};
                    setFormErrors(responseErrors);
                } else {
                    console.log('Error', error.message);
                }
            }).finally(() => setSending(false))
    }

    return (
        <Form onSubmit={onSubmitHandler}>

            <legend>Tytuł</legend>
            <hr className="mb-4"/>
            <Form.Group className="mb-3">
                <Form.Control size="lg" type="text" name="title"
                              value={process.title}
                              className={formErrors.title ? ' is-invalid' : ''}
                              onChange={onChangeHandler}
                />
                <FormError errors={formErrors?.title} />
            </Form.Group>

            <Row>
                <Col>
                    <legend>Serwis</legend>
                    <hr className="mb-4"/>

                    <Form.Group className="mb-3">
                        <Form.Label className="required">Oddział</Form.Label>
                        <DepartmentSelect
                            onChange={onDepartmentChangeHandler}
                            value={process?.departmentAccount}
                            className={formErrors.departmentAccount ? ' is-invalid' : ''}
                        />
                        <FormError errors={formErrors?.departmentAccount} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label className="required">Numer</Form.Label>
                        <Form.Control type="text" name="number"
                                      className={formErrors.number ? ' is-invalid' : ''}
                                      value={process.number ?? ''}
                                      onChange={onChangeHandler}
                        />
                        <FormError errors={formErrors?.number} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label className="required">Data przyjęcia</Form.Label>
                        <Form.Control type="date" name="date"
                                      className={"form-control " + (formErrors.date ? ' is-invalid' : '')}
                                      value={process.date}
                                      onChange={onChangeHandler}
                        />
                        <FormError errors={formErrors?.date} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label className="required">Przypisane do</Form.Label>
                        <UserSelect
                            value={process.user}
                            onChange={onProcessUserChangeHandler}
                        />
                        <FormError errors={formErrors?.user} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label className="required">Status:</Form.Label>
                        <DictionarySelect
                            model="ProcessStatus"
                            name="status"
                            value={process.status}
                            onChange={onStatusChangeHandler}
                            required={true}
                        />
                    </Form.Group>

                </Col>
                <Col>
                    <legend>Dane klienta</legend>
                    <hr className="mb-4"/>

                    <Form.Group className="mb-3">
                        <Form.Label>Wyszukaj klienta</Form.Label>

                        <ClientSelect
                            onChange={onClientChangeHandler}
                            value={process?.customer?.client ?? null}
                            isClearable={true}
                        />
                    </Form.Group>

                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Imię</Form.Label>
                                <Form.Control type="text" name="firstName"
                                      className={formErrors?.customer?.firstName ? ' is-invalid' : ''}
                                      value={process?.customer?.firstName ?? ''}
                                      onChange={onCustomerChangeHandler}
                                />
                                <FormError errors={formErrors?.customer?.firstName} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Nazwisko</Form.Label>
                                <Form.Control type="text" name="lastName"
                                              className={formErrors?.customer?.lastName ? ' is-invalid' : ''}
                                              value={process?.customer?.lastName ?? ''}
                                              onChange={onCustomerChangeHandler}
                                />
                                <FormError errors={formErrors?.customer?.lastName} />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>E-mail</Form.Label>
                                <Form.Control type="text" name="email"
                                              className={formErrors.customer?.email ? ' is-invalid' : ''}
                                              value={process?.customer?.email ?? ''}
                                              onChange={onCustomerChangeHandler}
                                />
                                <FormError errors={formErrors?.customer?.email} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label className="required">Telefon</Form.Label>
                                <Form.Control type="text" name="phone"
                                              className={formErrors?.customer?.phone ? ' is-invalid' : ''}
                                              value={process?.customer?.phone ?? ''}
                                              onChange={onCustomerChangeHandler}
                                />
                                <FormError errors={formErrors?.customer?.phone} />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Firma</Form.Label>
                                <Form.Control type="text" name="companyName"
                                              className={formErrors?.customer?.companyName ? ' is-invalid' : ''}
                                              value={process?.customer?.companyName ?? ''}
                                              onChange={onCustomerChangeHandler}
                                />
                                <FormError errors={formErrors?.customer?.companyName} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>NIP</Form.Label>
                                <Form.Control type="text" name="taxNumber"
                                              className={formErrors?.customer?.taxNumber ? ' is-invalid' : ''}
                                              value={process?.customer?.taxNumber ?? ''}
                                              onChange={onCustomerChangeHandler}
                                />
                                <FormError errors={formErrors?.customer?.taxNumber} />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Ulica</Form.Label>
                                <Form.Control type="text" name="street"
                                              className={formErrors?.customer?.address?.street ? ' is-invalid' : ''}
                                              value={process?.customer?.address?.street ?? ''}
                                              onChange={onCustomerAddressChangeHandler}
                                />
                                <FormError errors={formErrors?.customer?.address?.street} />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Kod pocztowy</Form.Label>
                                <Form.Control type="text" name="postCode"
                                              className={formErrors?.customer?.address?.postCode ? ' is-invalid' : ''}
                                              value={process?.customer?.address?.postCode ?? ''}
                                              onChange={onCustomerAddressChangeHandler}
                                />
                                <FormError errors={formErrors?.customer?.address?.postCode} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Miejscowość</Form.Label>
                                <Form.Control type="text" name="city"
                                              className={formErrors?.customer?.address?.city ? ' is-invalid' : ''}
                                              value={process?.customer?.address?.city ?? ''}
                                              onChange={onCustomerAddressChangeHandler}
                                />
                                <FormError errors={formErrors?.customer?.address?.postCode} />
                            </Form.Group>
                        </Col>
                    </Row>

                </Col>
            </Row>

            <legend>Szczegóły urządzenia i usterka</legend>
            <hr className="mb-4"/>
            {process.items?.map((processItem: ProcessItemFormType, keyItem: string | number) => {
                const itemErrors = formErrors?.items && formErrors?.items[0];
                return (
                    <div key={keyItem}>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Model</Form.Label>
                                    <Form.Control type="text" name="name"
                                                  className={itemErrors?.name ? ' is-invalid' : ''}
                                                  value={processItem.name}
                                                  onChange={onItemsChangeHandler}
                                    />
                                    <FormError errors={itemErrors?.name} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Numer seryjny</Form.Label>
                                    <Form.Control type="text" name="serialNumber"
                                                  className={itemErrors?.serialNumber ? ' is-invalid' : ''}
                                                  value={processItem.serialNumber}
                                                  onChange={onItemsChangeHandler}
                                    />
                                    <FormError errors={itemErrors?.serialNumber} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Opis usterki</Form.Label>
                                    <Form.Control as="textarea" name="note" rows={3}
                                                  onChange={onItemsChangeHandler}
                                                  value={processItem.note ?? ''}
                                    />
                                    <FormError errors={itemErrors?.note} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                )
            })}

            <legend>Opcje zgłoszenia</legend>
            <hr className="mb-4"/>

            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Dostawa do serwisu</Form.Label>
                        <ShipmentMethodSelect
                            type={ShipmentMethodTypeEnum.incoming}
                            value={process?.incomeShipment?.shipmentMethod}
                            onChange={onIncomeShipmentMethodChangeHandler}
                            className={formErrors?.incomeShipment?.shipmentMethod ? ' is-invalid' : ''}
                        />
                        <FormError errors={formErrors?.incomeShipment?.shipmentMethod} />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Zwrot do klienta</Form.Label>
                        <ShipmentMethodSelect
                            type={ShipmentMethodTypeEnum.outgoing}
                            value={process?.shipment?.shipmentMethod}
                            onChange={onShipmentMethodChangeHandler}
                            className={formErrors?.shipment?.shipmentMethod ? ' is-invalid' : ''}
                        />
                        <FormError errors={formErrors?.shipment?.shipmentMethod} />
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Uwagi do zgłoszenia</Form.Label>
                        <Form.Control as="textarea" name="note" rows={3}
                                      onChange={onChangeHandler}
                                      value={process.note ?? ''}
                        />
                        <FormError errors={formErrors?.note} />
                    </Form.Group>
                </Col>
            </Row>

            {/*<hr className="mb-4"/>*/}

            <Button size="lg" type="submit" variant="primary" disabled={isSending}>
                {isSending ? (
                    <><FontAwesomeIcon icon={faSpinnerThird} spin={true}/> wysyłam</>
                ) : 'Zapisz'}
            </Button>
        </Form>
    );
}

export default ProcessFormComponent;