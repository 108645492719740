import React, {useEffect} from "react";
import {Col, Row} from "react-bootstrap";
import {ProcessType, ProcessFormType, ProcessInitRequest} from "@process//types/process.types";
import {createProcess, initProcess} from "@process/utils/axios";
import {ProcessForm} from "@process/components";
import {Maybe} from "@src/types/global.types";
import {AxiosResponse} from "axios";

interface ProcessCreateProps {
}

const ProcessCreateView: React.FC<ProcessCreateProps> = () => {
    const [process, setProcess] = React.useState<Maybe<ProcessType>>();

    useEffect(() => {
        const initRequest: ProcessInitRequest = {};
        initProcess(initRequest).then((process: ProcessType) => {
            setProcess(process);
        });
    }, []);

    const saveProcess = async (data: ProcessFormType): Promise<AxiosResponse> => {
        return createProcess(data);
    }

    return (
        <div className="view-process-create">
            <h1>Nowy proces serwisowy</h1>

            <Row>
                <Col>
                    {process &&
                        <ProcessForm
                            process={process}
                            saveProcess={saveProcess}
                        />}
                </Col>
            </Row>

        </div>
    )
};

export default ProcessCreateView;