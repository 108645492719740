import {Maybe} from "@src/types/global.types";

export enum ShipmentMethodTypeEnum {
    incoming = 'incoming',
    outgoing = 'outgoing',
}

export interface ShipmentMethodListRequest {
    type: ShipmentMethodTypeEnum
}

export type ShipmentMethodType = {
    id: Maybe<string>
    createdAt: Maybe<string>
    updatedAt: Maybe<string>
    key: Maybe<string>
    type: Maybe<ShipmentMethodTypeEnum>
    name: Maybe<string>
    position: Maybe<number>
    price: Maybe<string>
    active: Maybe<boolean>
    default: Maybe<boolean>
}