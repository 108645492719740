import React, {ReactElement, useState} from 'react';
import {ClientFormErrorType, ClientType} from "@client/types/client.types";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinnerThird} from "@fortawesome/pro-regular-svg-icons";
import {Button, Col, Form, Row} from "react-bootstrap";
import {DictionarySelect} from "@dictionary/components";
import {FormError} from "@src/components";
import {mapClientToRequestType} from "@client/utils/mapper";
import {OnChangeValue, SelectOptionActionMeta} from "react-select/dist/declarations/src/types";
import {DictionaryType} from "@dictionary/types/dictionary.types";

interface ClientFormProps{
    client: ClientType,
    saveClient: Function,
}

const ClientFormComponent:React.FC<ClientFormProps> = ({client:initClient, saveClient}): ReactElement => {
    const navigate = useNavigate();
    const [formErrors, setFormErrors] = useState<ClientFormErrorType>({});
    const [client, setClient] = useState<ClientType>(initClient);
    const [isSending, setIsSending] = useState<boolean>(false)

    const onChangeHandler = e => {
        const name = e.target.name;
        const type = e.target.type;
        let value = null;

        if(type === 'file') {
            value = e.target.files[0];
        }else if(type === 'checkbox'){
            value = e.target.checked;
        }else{
            value = e.target.value;
        }

        setClient(prevState => {
            return {...prevState, ...{[name]: value}}
        });
    }

    const onDictionaryChangeHandler = (
        dictionary: OnChangeValue<DictionaryType, false>,
        actionMeta: SelectOptionActionMeta<DictionaryType>
    ) => {
        setClient(prevState => {
            return {...prevState, ...{[actionMeta.name]: dictionary}}
        });
    }

    const onSubmitHandler = async e =>  {
        e.preventDefault();
        setIsSending(true);

        saveClient(mapClientToRequestType(client))
            .then(response => {
                toast.success("Klient został zapisany");
                navigate(`/client/${response.data.id}`);
            })
            .catch(error => {
                if (400 === error.response.status) {
                    const responseErrors = error.response?.data?.errors || {};
                    setFormErrors(responseErrors);
                } else {
                    console.log('Error', error.message);
                }
            }).finally(() => {
                setIsSending(false);
            })
    }

    return (
        <Form onSubmit={onSubmitHandler}>

            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Imię:</Form.Label>
                        <Form.Control
                            type="text" name="firstName"
                            value={client?.firstName ?? ''}
                            onChange={onChangeHandler}
                            isInvalid={!!formErrors.firstName}
                        />
                        <FormError errors={formErrors.firstName} />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Nazwisko:</Form.Label>
                        <Form.Control
                            type="text" name="lastName"
                            value={client?.lastName ?? ''}
                            onChange={onChangeHandler}
                            isInvalid={!!formErrors.lastName }
                        />
                        <FormError errors={formErrors.lastName} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Firma:</Form.Label>
                        <Form.Control
                            type="text" name="companyName"
                            value={client?.companyName ?? ''}
                            onChange={onChangeHandler}
                            isInvalid={!!formErrors.companyName}
                        />
                        <FormError errors={formErrors.companyName} />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>NIP:</Form.Label>
                        <Form.Control
                            type="text" name="nip"
                            value={client?.nip ?? ''}
                            onChange={onChangeHandler}
                            isInvalid={!!formErrors.nip}
                        />
                        <FormError errors={formErrors.nip} />
                    </Form.Group>
                </Col>

            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>E-mail:</Form.Label>
                        <Form.Control
                            type="text" name="email"
                            value={client?.email ?? ''}
                            onChange={onChangeHandler}
                            isInvalid={!!formErrors.email }
                        />
                        <FormError errors={formErrors.email} />
                    </Form.Group>
                </Col>

                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Telefon:</Form.Label>
                        <Form.Control
                            type="text" name="phone"
                            value={client?.phone ?? ''}
                            onChange={onChangeHandler}
                            isInvalid={!!formErrors.phone }
                        />
                        <FormError errors={formErrors.phone} />
                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <Col xs={6}>
                    <Form.Group className="mb-3">
                        <Form.Label className="required">Status:</Form.Label>
                        <DictionarySelect
                            model="ClientStatus" name="status"
                            value={client.status}
                            onChange={onDictionaryChangeHandler}
                            required={true}
                            isInvalid={!!formErrors.status}
                        />
                        <FormError errors={formErrors.status} />
                    </Form.Group>
                </Col>
            </Row>
            <hr/>

            <Row>
                <Col xs={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>Ulica:</Form.Label>
                        <Form.Control
                            type="text" name="street"
                            value={client?.street ?? ''}
                            onChange={onChangeHandler}
                            isInvalid={!!formErrors.street}
                        />
                        <FormError errors={formErrors.street} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Kod pocztowy:</Form.Label>
                        <Form.Control
                            type="text" name="postCode"
                            value={client.postCode ?? ''}
                            onChange={onChangeHandler}
                            isInvalid={!!formErrors.postCode}
                        />
                        <FormError errors={formErrors.postCode} />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Miejscowość:</Form.Label>
                        <Form.Control
                            type="text" name="city"
                            value={client.city ?? ''}
                            onChange={onChangeHandler}
                            isInvalid={!!formErrors.city}
                        />
                        <FormError errors={formErrors.city} />
                    </Form.Group>
                </Col>
            </Row>

            <hr/>

            <Button type="submit" className="btn btn-primary" disabled={isSending}>
                {isSending
                    ? <><FontAwesomeIcon icon={faSpinnerThird} spin={isSending} /> wysyłam...</>
                    : 'Zapisz'
                }
            </Button>
        </Form>
    );
}

export default ClientFormComponent;
