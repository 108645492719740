import React from "react";
import { ProcessType } from "../../types/process.types";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faPhone} from "@fortawesome/pro-light-svg-icons";

interface ProcessShipmentProps{
    process: ProcessType
}

const ProcessInfoComponent: React.FC<ProcessShipmentProps> = ({process}) => {

    return (
        <>
            <dl className="dl-horizontal">
                <dt>Number zgłoszenia:</dt>
                <dd>
                    <h5 className='m-0'>
                        <Link to={`/process/${process.id}`}>
                            {process.number}
                        </Link>
                    </h5>
                </dd>
            </dl>
            <dl className="dl-horizontal">
                <dt>Klient:</dt>
                <dd>
                    <div>
                        <Link
                            to={process?.customer?.client ? `/client/${process.customer.client.id}` : '#'}
                        >
                            {process.customer.firstName} {process.customer.lastName}
                        </Link>
                    </div>
                    {process.customer.companyName && (
                        <div>{process.customer.companyName}</div>
                    )}

                    <div className="mt-1">
                        <FontAwesomeIcon icon={faEnvelope} fixedWidth={true}/>&nbsp;
                        <a href={"mailto:" + process.customer.email}>
                            {process.customer.email}
                        </a>
                    </div>
                    <div>
                        <FontAwesomeIcon icon={faPhone} fixedWidth={true}/>&nbsp;
                        <a href={"mailto:" + process.customer.phone}>
                            {process.customer.phone}
                        </a>
                    </div>
                </dd>
            </dl>
            {process.departmentAccount && (
                <dl className="dl-horizontal">
                    <dt>Oddział:</dt>
                    <dd>{process.departmentAccount.code} - {process.departmentAccount.name}</dd>
                </dl>
            )}
            <dl className="dl-horizontal">
                <dt>Data przyjęcia:</dt>
                <dd>
                    <div className="d-flex justify-content-between align-items-center">
                        <div>{process.date}</div>
                        <div className="fst-italic small text-muted">{process.createdAt}</div>
                    </div>
                </dd>
            </dl>
            <dl className="dl-horizontal border-0">
                <dt>Przypisane do:</dt>
                <dd>{process.user.name} {process.user.surname}</dd>
            </dl>
        </>
    );
};

export default ProcessInfoComponent;