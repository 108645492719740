import AppLayout from "@src/components/AppLayout";
import {RouterProvider, createBrowserRouter, RouteObject} from "react-router-dom";
import App404 from "@src/components/App404";
import {OfferView, OfferCreateView, OfferListView, OfferEditView} from "@offer/views";
import React from "react";
import {ToastContainer} from "react-toastify";
import {ClientListView, ClientCardView, ClientCreateView, ClientEditView} from "@client/views";
import {UserEditView, UserListView, UserCardView, UserCreateView} from "@user/views";
import {ProcessCreateView, ProcessListView, ProcessEditView, ProcessCardView} from "@process/views";
import {EstimateCardView, EstimateListView} from "@estimate/views";
import {useSelector} from "react-redux";
import {auth, AuthState} from "@src/modules/auth/store/authSlice";
import type {Router as RemixRouter} from "@remix-run/router/dist/router";
import {AuthRoles} from "@src/modules/auth/utils/AuthRoles";
import {RecoverPasswordView, ChangePasswordView, LoginView} from "@auth/views";

const createRouter = (authData: AuthState): RemixRouter => {

    if (false === authData.isAuth) {
        return createBrowserRouter([
            {path: '/*', element: <LoginView />},
            {path: '/recover-password', element: <RecoverPasswordView/>},
            {path: '/change-password/:token', element: <ChangePasswordView/>},
        ]);
    }

    let appLayoutRouteObjects: RouteObject[] = [];

    const hasAdminRole = !!authData.roles.find(role => role === AuthRoles.ROLE_ADMIN);
    const hasUserRole = !!authData.roles.find(role => role === AuthRoles.ROLE_USER);

    if (hasAdminRole) {
        appLayoutRouteObjects.push(
            {path: '/user/create', element: <UserCreateView/>},
            {path: '/user/edit/:id', element: <UserEditView/>},
        );
    }

    if (hasUserRole) {
        appLayoutRouteObjects.push(
            {path: '/', element: <OfferListView/>},
            {path: '/offer', element: <OfferListView/>},
            {path: '/offer/:id', element: <OfferView/>},
            {path: '/offer/create', element: <OfferCreateView/>},
            {path: '/offer/edit/:id', element: <OfferEditView/>},
            {path: '/client', element: <ClientListView/>},
            {path: '/client/:id', element: <ClientCardView/>},
            {path: '/client/create', element: <ClientCreateView/>},
            {path: '/client/edit/:id', element: <ClientEditView/>},
            {path: '/user', element: <UserListView/>},
            {path: '/user/:id', element: <UserCardView/>},
            {path: '/process', element: <ProcessListView/>},
            {path: '/process/:id', element: <ProcessCardView/>},
            {path: '/process/create', element: <ProcessCreateView/>},
            {path: '/process/edit/:id', element: <ProcessEditView/>},
            {path: '/estimate', element: <EstimateListView/>},
            {path: '/estimate/:id', element: <EstimateCardView/>},
        );
    }

    appLayoutRouteObjects.push({path: '*', element: <App404/>});

    return createBrowserRouter([
        {path: '/', element: <AppLayout/>, children: appLayoutRouteObjects},
    ]);
}

function App() {
    const authData: AuthState = useSelector(auth);

    return (
        <div className="App">
            <ToastContainer/>
            <RouterProvider router={createRouter(authData)}/>
        </div>
    );
}

export default App;
