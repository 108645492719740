import React, {useEffect, useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import {parseFilterParamsFromUrl} from "@src/helpers/utils.helper";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faGear, faPlus} from '@fortawesome/pro-regular-svg-icons'
import {NumberFormat, SortableColumn} from "@src/components";
import {OfferFilterRequestType, OfferType} from "@offer/types/offer.types";
import Table from "react-bootstrap/Table";
import {OfferDropdownOptions, OfferFilter} from "@offer/components";
import {Maybe, PaginationDataType, PaginationType} from "@src/types/global.types";
import {Pagination} from "@src/components";
import {getOfferCollection} from "@offer/utils/axios";
import {Alert} from "react-bootstrap";

interface OfferListProps {
}

const OfferListView: React.FC<OfferListProps> = () => {
    const locationParams = useLocation().search;
    const [offerList, setOfferList] = useState<Maybe<OfferType[]>>([]);
    const [pagination, setPagination] = useState<Maybe<PaginationType>>();
    const [offerFilter, setOfferFilter] = useState<OfferFilterRequestType>(parseFilterParamsFromUrl(locationParams));
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isLoad, setLoad] = useState<boolean>(false);

    useEffect(() => {
        const newOfferFilter: OfferFilterRequestType = parseFilterParamsFromUrl(locationParams);
        if (JSON.stringify(newOfferFilter) !== JSON.stringify(offerFilter)) {
            setOfferFilter(newOfferFilter);
        }
    }, [locationParams])

    const fetchOfferCollection = () => {
        setLoading(true);
        getOfferCollection(offerFilter).then((paginationData: PaginationDataType<OfferType>) => {
            setOfferList(paginationData.items);
            setPagination(paginationData.pagination);
            setLoading(false);
            setLoad(true);
        });
    }

    useEffect(fetchOfferCollection, [offerFilter]);
    useEffect(() => { window.scrollTo(0,0); }, [offerList]);

    return (
        <>
            <div className="d-flex align-content-center align-items-center justify-content-between mb-3">
                <div>
                    <h1 className="m-0">Lista ofert</h1>
                </div>
                <div>
                    <Link to="/offer/create" className="btn btn-outline-primary">
                        <FontAwesomeIcon icon={faPlus} fixedWidth={true}/> Dodaj ofertę
                    </Link>
                </div>
            </div>

            <OfferFilter clientFilterRequest={offerFilter}/>
            {offerList.length === 0 && isLoad && (
                <Alert variant="info">Brak wyników wyszukiwania</Alert>
            )}

            {offerList.length > 0 && (
                <>
                    <Table hover bordered size="sm">
                        <thead>
                        <tr>
                            <th scope="col">
                                <SortableColumn label="Data dodania" orderKey="createdAt" params={offerFilter}/>
                            </th>
                            <th scope="col">
                                <SortableColumn label="Dodał" orderKey="createdBy" params={offerFilter}/>
                            </th>
                            <th scope="col">
                                <SortableColumn label="Numer" orderKey="number" params={offerFilter}/>
                            </th>
                            <th scope="col">
                                <SortableColumn label="Tytuł" orderKey="title" params={offerFilter}/>
                            </th>
                            <th scope="col">
                                <SortableColumn label="Klient" orderKey="client" params={offerFilter}/>
                            </th>
                            <th scope="col" className="text-center">
                                <SortableColumn label="Status" orderKey="status" params={offerFilter}/>
                            </th>
                            <th scope="col" className="text-center">
                                <SortableColumn label="Termin" orderKey="termDate" params={offerFilter}/>
                            </th>
                            <th scope="col" className="text-end">
                                <SortableColumn label="Wartość bazowa" orderKey="priceRegular" params={offerFilter}/>
                            </th>
                            <th scope="col" className="text-end">
                                <SortableColumn label="Rabat" orderKey="priceDiscount" params={offerFilter}/>
                            </th>
                            <th scope="col" className="text-end">
                                <SortableColumn label="Rabat%" orderKey="percentDiscount" params={offerFilter}/>
                            </th>
                            <th scope="col" className="text-end">
                                <SortableColumn label="Wartość" orderKey="price" params={offerFilter}/>
                            </th>
                            <th scope="col" className="text-end">
                                <a href="#"><FontAwesomeIcon icon={faGear}/></a>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {offerList.map(offer => (
                            <tr key={offer.id}>
                                <td>{offer.createdAt}</td>
                                <td>{offer.createdBy.name + ' ' + offer.createdBy.surname}</td>
                                <td>
                                    <Link to={`/offer/${offer.id}`}>
                                        {offer.number}
                                    </Link>
                                </td>
                                <td>
                                    <Link to={`/offer/${offer.id}`}>
                                        {offer.title}
                                    </Link>
                                </td>
                                <td>
                                    <Link to={`/client/${offer.client.id}`}>
                                        {offer.client.firstName}
                                    </Link>
                                </td>
                                <td className="text-center">
                                    {offer.status.name}
                                </td>
                                <td className="text-center">
                                    {offer.termDate}
                                </td>
                                <td className="text-end">
                                    <NumberFormat value={offer.priceRegular}/>
                                </td>
                                <td className="text-end">
                                    <NumberFormat value={offer.priceDiscount}/>
                                </td>
                                <td className="text-end">
                                    <NumberFormat value={offer.percentDiscount} extension="%"/>
                                </td>
                                <td className="text-end">
                                    <NumberFormat value={offer.price}/>
                                </td>
                                <td>
                                    <OfferDropdownOptions
                                        offer={offer}
                                        fetchOffers={fetchOfferCollection}
                                        buttonSize={"sm"}
                                    />
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    {pagination && <Pagination pagination={pagination}/>}
                </>
            )}
        </>
    );
}

export default OfferListView;