import React from "react";
import {UserForm} from "@user/components";
import {Col, Row} from "react-bootstrap";

interface UserCreateProps {}

const UserCreateView: React.FC<UserCreateProps> = () => {
    return (
        <div className="view-user-create">
            <h1>Nowy użytkownik</h1>
            <Row>
                <Col sm md={{span: 8, offset: 2}} >
                    <UserForm user={{}}/>
                </Col>
            </Row>
        </div>
    );
}

export default UserCreateView;
