import {client as clientAxios} from "@src/library/axios.library";
import {AxiosResponse} from "axios";
import {ClientFilterRequestType, ClientRequestType, ClientType} from "@client/types/client.types";
import {PaginationDataType} from "@src/types/global.types";

export const getClient = async (id: string): Promise<ClientType> => {
    const {data} = await clientAxios.get<never, AxiosResponse<ClientType>>('/api/v1/client/' + id);
    return data;
}

export const getClientCollection = async (filter: ClientFilterRequestType): Promise<PaginationDataType<ClientType>> => {
    const {data} = await clientAxios.post<ClientFilterRequestType, AxiosResponse<PaginationDataType<ClientType>>>('/api/v1/client', filter);
    return data;
}

export const initClient = async (): Promise<ClientType> => {
    const {data} =  await clientAxios.get<ClientRequestType, AxiosResponse<ClientType>>(`/api/v1/client/init`);
    return data;
}

export const createClient = async (data: ClientRequestType): Promise<AxiosResponse> => {
    return await clientAxios.post<ClientRequestType, AxiosResponse>(`/api/v1/client/create`, data);
}

export const updateClient = async (clientId: string, data: ClientRequestType): Promise<AxiosResponse> => {
    return await clientAxios.patch<ClientRequestType, AxiosResponse>(`/api/v1/client/${clientId}/update`, data);
}

