import React, {useEffect, useState} from "react";
import {Form} from "react-bootstrap";
import {findList} from "@shipment/uitls/axios";
import {
    ShipmentMethodType,
    ShipmentMethodListRequest,
    ShipmentMethodTypeEnum
} from "@shipment/types/shipmentMethod.types";
import {Maybe} from "@src/types/global.types";

interface ShipmentMethodSelectProps {
    type: ShipmentMethodTypeEnum,
    onChange: Function,//(shipmentMethod: ShipmentMethodType),
    name?: Maybe<string>,
    value?: Maybe<ShipmentMethodType>,
    className?: Maybe<string>
}

const ShipmentMethodSelectComponent: React.FC<ShipmentMethodSelectProps> = (
    {type, name, value, onChange, className}
) => {
    const [shipmentMethodList, setShipmentMethodList] = useState<ShipmentMethodType[]>([])

    const onChangeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value: string = e.target.value;
        const selectedShipmentMethod: Maybe<ShipmentMethodType> = shipmentMethodList.find((shipmentMethod: ShipmentMethodType) => {
            return value === shipmentMethod.id;
        })
        onChange(selectedShipmentMethod, name);
    }

    useEffect(() => {
        const request: ShipmentMethodListRequest = {type: type}
        findList(request).then((response: ShipmentMethodType[]) => {
            setShipmentMethodList(response)
        });
    }, []);

    return (
        <Form.Select
            value={value?.id ?? ""}
            name={name}
            onChange={onChangeHandler}
            className={'form-select ' + (className ? ` ${className}` : '')}
        >
            <option value="">- wybierz metodę dostawy -</option>
            {shipmentMethodList.map((shipmentMethod: ShipmentMethodType) => (
                <option value={shipmentMethod.id} key={shipmentMethod.id}>
                    {shipmentMethod.name}
                </option>
            ))}
        </Form.Select>
    );
}

export default ShipmentMethodSelectComponent;