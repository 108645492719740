import React, {useEffect, useState} from 'react';
import {ClientDropdownOptions, ClientFormComponent} from "../../components";
import {Link, useParams} from "react-router-dom";
import {ClientRequestType, ClientType} from "../../types/client.types";
import {getUuidFromId} from "@src/helpers/utils.helper";
import {Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/pro-regular-svg-icons";
import {Maybe} from "@src/types/global.types";
import {getClient, updateClient} from "@client/utils/axios";
import {AxiosResponse} from "axios";

interface ClientEditProps{}

const ClientEditView:React.FC<ClientEditProps> = () => {
    const {id} = useParams();
    const [client, setClient] = useState<Maybe<ClientType>>();

    useEffect(() => {
        getClient(id).then((client:ClientType) => setClient(client));
    }, []);

    const saveClient = async (data: ClientRequestType): Promise<AxiosResponse> => {
        return updateClient(client.id, data);
    }

    return (
        <>
            <h1>Edycja klienta</h1>
            {client && (
                <>
                    <Row className="mb-3">
                        <Col xs="auto">
                            <Link to={'/client/' + getUuidFromId(client.id)} className="btn btn-outline-primary">
                                <FontAwesomeIcon icon={faEye} fixedWidth /> Karta klienta
                            </Link>
                        </Col>
                        <Col xs="auto">
                            <ClientDropdownOptions client={client} />
                        </Col>
                    </Row>

                    <ClientFormComponent client={client} saveClient={saveClient}/>
                </>
            )}
        </>
    )
}

export default ClientEditView;
