import React, {useEffect, useState} from 'react';

import {ClientFormComponent} from "../../components";
import {ClientRequestType, ClientType} from "../../types/client.types";
import {AxiosResponse} from "axios";
import {createClient, initClient} from "@client/utils/axios";
import {Maybe} from "@src/types/global.types";

const ClientCreateView = () => {
    const [client, setClient] = useState<Maybe<ClientType>>();

    useEffect(() => {
        initClient().then((client:ClientType) => setClient(client));
    }, []);


    const saveClient = async (data: ClientRequestType): Promise<AxiosResponse> => {
        return createClient(data);
    }

    return (
        <>
            <h1>Dodanie nowego klienta</h1>
            {client && <ClientFormComponent client={client} saveClient={saveClient}/>}
        </>
    );

}

export default ClientCreateView;