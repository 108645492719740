import React, {useEffect, useState} from "react";
import { Maybe } from "@src/types/global.types";
import Select from "react-select";
import {DictionaryFilterType, DictionaryType} from "@dictionary/types/dictionary.types";
import {getDictionaryCollection} from "@dictionary/utils/axios";
import {OnChangeValue} from "react-select/dist/declarations/src/types";

interface DictionarySelectProps {
    model: string,
    onChange: any,
    name?: Maybe<string>,
    value?: Maybe<DictionaryType>,
    required?: boolean,
    className?: Maybe<string>
    isClearable?: Maybe<boolean>
    placeholder?: Maybe<string>,
    isInvalid?: Maybe<boolean>
}

const DictionarySelectComponent: React.FC<DictionarySelectProps> = (
    {model, onChange, value: initValue, name, className, isClearable, placeholder, isInvalid}
) => {
    const [dictionaryCollection, setDictionaryCollection] = useState<DictionaryType[]>([]);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [selectedValue, setSelectedValue] = useState<Maybe<DictionaryType>>();

    const fetchDictionaryCollection = () => {
        const filter: DictionaryFilterType = {model: model}
        setLoading(true);
        getDictionaryCollection(filter)
            .then((response: DictionaryType[]) => setDictionaryCollection(response))
            .finally(() => setLoading(false))
        ;
    }

    const loadSelectedValue = () => {
        if (!(initValue?.id ?? null)) {
            setSelectedValue(null);
            return;
        }

        if (initValue && selectedValue && initValue.id === selectedValue.id) {
            const selectedUserOK = Object.keys(selectedValue).length > 1;
            if (selectedUserOK) {
                return;
            }
        }

        const initUserOK =  Object.keys(initValue).length > 1;
        if (initUserOK) {
            setSelectedValue(initValue);
            return;
        }

        setSelectedValue(dictionaryCollection.find((dictionary: DictionaryType) => dictionary.id === initValue.id));
    }

    const onChangeHandler = (dictionary: OnChangeValue<DictionaryType, false>) => {
        setSelectedValue(dictionary);
        onChange(dictionary, name);
    }

    useEffect(loadSelectedValue, [initValue, dictionaryCollection]);
    useEffect(fetchDictionaryCollection, []);

    if (isInvalid) {
        className = ` is-invalid ${className}`
    }

    return (
        <Select
            options={dictionaryCollection}
            name={name}
            value={selectedValue}
            className={'p-0 form-select ' + (className ? ` ${className}` : '')}
            getOptionLabel={(dictionary: DictionaryType) => dictionary.name}
            getOptionValue={(dictionary: DictionaryType) => dictionary.id}
            onChange={onChangeHandler}
            isLoading={isLoading}
            isClearable={isClearable}
            placeholder={placeholder ?? null}
            styles={{
                control: (baseStyles) => ({...baseStyles, width: 'auto', border: '0', minHeight: '0'}),
                dropdownIndicator: (baseStyles) => ({...baseStyles, padding: '6px 8px'}),
                clearIndicator: (baseStyles) => ({...baseStyles, padding: '6px 8px'}),
            }}
        />
    );
}

export default DictionarySelectComponent;
