import {OfferFilterRequestType, OfferFilterType, OfferRequestType, OfferType} from "@offer/types/offer.types";

export const mapOfferToOfferRequestType = (offer: OfferType): OfferRequestType => {
    return {
        id: offer.id,
        title: offer.title,
        number: offer.number,
        department: offer?.department?.id ?? null,
        status: offer?.status?.id ?? null,
        termDate: offer.termDate,
        client: offer?.client?.id ?? null,
        description: offer.description,
    }
}


export const mapOfferFilterRequestToOfferFilterType =
    (offerFilterRequestType: OfferFilterRequestType)
        : OfferFilterType => {
        return {
            search: offerFilterRequestType?.search ?? '',
            page: offerFilterRequestType.page ?? 1,
            department: {id: offerFilterRequestType?.department ?? null},
            status: {id: offerFilterRequestType?.status ?? null},
            createdBy: {id: offerFilterRequestType?.createdBy ?? null},
            client: {id: offerFilterRequestType?.client ?? null},
        }
    }