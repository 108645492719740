import {EstimateItemRequestType, EstimateItemType} from "../types/estimateItem.types";


export const mapEstimateItemToRequestType = (estimateItem: EstimateItemType): EstimateItemRequestType => {
    return {
        product: estimateItem.product?.id ?? null,
        name: estimateItem.name ?? null,
        sku: estimateItem.sku ?? null,
        quantity: estimateItem?.quantity?.toString() ?? null,
        unit: estimateItem.unit?.id ?? null,
        unitPriceGross: estimateItem.unitPriceGross ?? null,
        note: estimateItem.note ?? null,
    };
}