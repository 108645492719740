import React, {ChangeEventHandler, FormEventHandler, useState} from "react";
import {Button, Form} from "react-bootstrap";
import {RecoverPasswordFormErrorType, RecoverPasswordFormType} from "@auth/types/auth.types";
import {recoverPassword} from "@auth/utils/axios";
import {FormError} from "@src/components";

interface RecoverPasswordProps {
}

const RecoverPasswordComponent: React.FC<RecoverPasswordProps> = ({}) => {
    const [isSending, setSending] = React.useState<boolean>(false);
    const [isSent, setSent] = React.useState<boolean>(false);
    const [formData, setFormData] = React.useState<RecoverPasswordFormType>({});
    const [formErrors, setFormErrors] = useState<RecoverPasswordFormErrorType>({});


    const onChangeHandler: ChangeEventHandler<HTMLElement> = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value: string = e.target.value;
        const name: string = e.target.name;

        setFormData(prevState => {
            return {...prevState, [name]: value};
        })
    }

    const onSubmitHandler: FormEventHandler = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSending(true);

        recoverPassword(formData)
            .then(() => {
                setSent(true);
            })
            .catch(error => {
                if (400 === error.response.status) {
                    const responseErrors = error.response?.data?.errors || {};
                    setFormErrors(responseErrors);
                } else {
                    console.log('Error', error.message);
                }
            })
            .finally(() => setSending(false))
    }

    if (isSent) {
        return (
            <div className="recover-password-component">
                <p><strong>{formData.email}</strong></p>
                <p>
                    Jeśli na podany adres e-mail jest założone konto, to wysłaliśmy na niego wiadomość.
                </p>
            </div>
        );
    }

    return (
        <div className="recover-password-component">

            <Form onSubmit={onSubmitHandler}>

                <Form.Group className="mb-3">
                    <Form.Label className="required">Twój e-mail:</Form.Label>
                    <Form.Control
                        type="email"
                        name="email"
                        value={formData.email ?? ''}
                        onChange={onChangeHandler}
                        className={formErrors.email ? ' is-invalid' : ''}
                        placeholder="E-mail"
                    />
                    <FormError errors={formErrors.email} />
                </Form.Group>

                <br/>
                <Button type="submit" variant="outline-primary" disabled={isSending}>
                    {isSending ? 'Wysyłam...' : 'Wyślij'}
                </Button>
            </Form>
        </div>
    );
};

export default RecoverPasswordComponent