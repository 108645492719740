import React, {useState} from "react";

import {useDispatch} from "react-redux";
import {login} from "@auth/store/authSlice";
import {AppDispatch} from "@src/store";
import {client} from "@src/library/axios.library";
import {Button, Form} from "react-bootstrap";

export interface LoginForm {
    email: string,
    password: string,
}

const defaultLoginForm: LoginForm = {
    email: '',
    password: '',
};

const LoginComponent: React.FC = (props) => {
    const dispatch = useDispatch<AppDispatch>();
    const [formData, setFormData] = useState<LoginForm>(defaultLoginForm);
    const [loginError, setLoginError] = useState<boolean>(false);
    const [isSending, setIsSending] = useState<boolean>(false)

    const onChangeHandler = e => {
        const name = e.target.name;
        const type = e.target.type;
        let value = null;

        if(type === 'checkbox'){
            value = e.target.checked;
        }else{
            value = e.target.value;
        }

        setLoginError(false);
        setFormData(prevState => {
            return {...prevState, ...{[name]: value}}
        });
    }

    const onSubmitHandler = (e) => {
        e.preventDefault();
        setIsSending(true);
        client.post('/api/login', formData)
            .then(response => {
                dispatch(login({'token': response.data.token}));
            })
            .catch(error => {
                setLoginError(true);
            }).finally(() => {
                setIsSending(false);
            })
        ;
    }

    return (
        <div className="login-component">
            <Form onSubmit={onSubmitHandler}>
                <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">E-mail</label>
                    <input
                        type="email"
                        name="email"
                        required={true}
                        value={formData.email}
                        onChange={onChangeHandler}
                        className={"form-control " + (loginError ? ' is-invalid' : '')}
                    />
                    {loginError && (
                        <div className="invalid-feedback">Błędny login lub hasło</div>
                    )}
                </div>
                <div className="mb-3">
                    <label htmlFor="exampleInputPassword1" className="form-label">Hasło</label>
                    <input
                        type="password"
                        name="password"
                        required={true}
                        value={formData.password}
                        onChange={onChangeHandler}
                        className={"form-control " + (loginError ? ' is-invalid' : '')}
                    />
                </div>
                <br/>
                <Button type="submit" variant='outline-primary' disabled={isSending}>
                    {isSending ? 'Loguję...' : 'Zaloguj'}
                </Button>
            </Form>
        </div>
    );
}

export default LoginComponent;