import React, {ReactElement, useEffect, useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import {Maybe} from "@src/graphql/$graphql";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGear, faPlus} from "@fortawesome/pro-regular-svg-icons";
import {ClientFilterRequestType, ClientType} from "@client/types/client.types";
import {ClientDropdownOptions, ClientFilter} from "@client/components";
import Table from "react-bootstrap/Table";
import {PaginationDataType, PaginationType} from "@src/types/global.types";
import {getClientCollection} from "@client/utils/axios";
import {Pagination, SortableColumn} from "@src/components";
import {parseFilterParamsFromUrl} from "@src/helpers/utils.helper";
import {Alert} from "react-bootstrap";

interface ClientListProps {
}

const ClientListView: React.FC<ClientListProps> = (): ReactElement => {
    const locationParams = useLocation().search;
    const [clientCollection, setClientCollection] = useState<ClientType[]>([]);
    const [clientFilter, setClientFilter] = useState<ClientFilterRequestType>(parseFilterParamsFromUrl(locationParams));
    const [pagination, setPagination] = useState<Maybe<PaginationType>>();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isLoad, setLoad] = useState<boolean>(false);

    const fetchClientCollection = () => {
        setLoading(true);
        getClientCollection(clientFilter).then((paginationData: PaginationDataType<ClientType>) => {
            setClientCollection(paginationData.items);
            setPagination(paginationData.pagination);
            setLoading(false);
            setLoad(true);
        });
    }

    useEffect(() => {
        const newClientFilter: ClientFilterRequestType = parseFilterParamsFromUrl(locationParams);
        if (JSON.stringify(newClientFilter) !== JSON.stringify(clientFilter)) {
            setClientFilter(newClientFilter);
        }
    }, [locationParams])

    useEffect(fetchClientCollection, [clientFilter]);
    useEffect(() => { window.scrollTo(0,0); }, [clientCollection]);

    return (
        <>
            <div className="d-flex align-content-center align-items-center justify-content-between mb-3">
                <div>
                    <h1 className="m-0">Lista klientów</h1>
                </div>
                <div>
                    <Link to="/client/create" className="btn btn-outline-primary">
                        <FontAwesomeIcon icon={faPlus} fixedWidth={true}/> Dodaj klient
                    </Link>
                </div>
            </div>

            <ClientFilter clientFilterRequest={clientFilter}/>

            {clientCollection.length === 0 && isLoad && (
                <Alert variant="info">Brak wyników wyszukiwania</Alert>
            )}

            {clientCollection.length > 0 && (
                <>
                    <Table hover bordered size="sm">
                        <thead>
                        <tr>
                            <th scope="col">
                                <SortableColumn label="Dodano" orderKey="createdAt" params={clientFilter}/>
                            </th>
                            <th scope="col">
                                <SortableColumn label="Nazwa" orderKey="name" params={clientFilter}/>
                            </th>
                            <th scope="col">Nip</th>
                            <th scope="col">
                                <SortableColumn label="E-mail" orderKey="email" params={clientFilter}/>
                            </th>
                            <th scope="col">Telefon</th>
                            <th scope="col">
                                <SortableColumn label="Adres" orderKey="city" params={clientFilter}/>
                            </th>
                            <th scope="col" className="text-center">
                                <a href="#">
                                    <FontAwesomeIcon icon={faGear}/>
                                </a>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {clientCollection.map((client: ClientType) => (
                            <tr key={client.id}>
                                <td>{moment(client.createdAt).format('YYYY-MM-DD HH:mm')}</td>
                                <td>
                                    <Link to={`/client/${client.id}`}>
                                        <div>{`${client.firstName ?? ''} ${client.lastName ?? ''}`}</div>
                                        <div>{client.companyName ?? ''}</div>
                                    </Link>
                                </td>
                                <td>{client.nip}</td>
                                <td>{client.email}</td>
                                <td>{client.phone}</td>
                                <td>
                                    <div>{client.street}</div>
                                    <div>{client.postCode}&nbsp;{client.city}</div>
                                </td>
                                <td className="text-center">
                                    <ClientDropdownOptions client={client} buttonSize={"sm"}/>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    {pagination && <Pagination pagination={pagination}/>}
                </>
            )}
        </>
    );
}

export default ClientListView;