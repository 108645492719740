import React, {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import {
    ProcessType,
    ProcessErrorType,
    ProcessFormType,
    ProcessItemFormType,
    ProcessItemType
} from "@process/types/process.types";
import {getProcess, updateProcess} from "@process/utils/axios";
import {toast} from "react-toastify";
import {useNavigate, useParams} from "react-router-dom";
import {ProcessDropdownOptions, ProcessForm} from "@process/components";
import {Maybe} from "@src/types/global.types";
import {ClientRequestType} from "@client/types/client.types";
import {AxiosResponse} from "axios";
import {updateClient} from "@client/utils/axios";

interface ProcessEditProps {
}

const ProcessEditView: React.FC<ProcessEditProps> = () => {
    // const [processForm, setProcessForm] = React.useState<Maybe<ProcessFormType>>();
    // const [formErrors, setFormErrors] = useState<ProcessErrorType>({});
    const [process, setProcess] = useState<Maybe<ProcessType>>();
    // const [isSending, setIsSending] = useState<boolean>(false);
    // const navigate = useNavigate();
    const { id: processId } = useParams();

    useEffect(() => {
        getProcess(processId).then((process: ProcessType) => {
            let processItems: ProcessItemType[] = [];
            process.items.map(processItem => processItems.push({
                id: processItem.id,
                name: processItem.name,
                serialNumber: processItem.serialNumber,
                note: processItem.note,
            }));

            setProcess(process);
        });
    }, []);

    const saveProcess = async (data: ClientRequestType): Promise<AxiosResponse> => {
        return updateProcess(process.id, data);
    }

    return (
        <div className="view-process-create">
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h1 className="m-0">Edycja procesu serwisowego</h1>
                <div>
                    {process && <ProcessDropdownOptions process={process} buttonVariant="outline-primary"/>}
                </div>
            </div>

            <Row>
                <Col>
                    {process && <ProcessForm process={process} saveProcess={saveProcess} /> }
                </Col>
            </Row>

        </div>
    )
};

export default ProcessEditView;