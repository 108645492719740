import React, {ReactElement, useEffect} from "react";
import FilterComponent from "@src/components/Filter/Filter.component";
import FilterSearch from "@src/components/Filter/components/FilterSearch/FilterSearch.component";
import {OfferFilterRequestType} from "@offer/types/offer.types";
import {ProcessFilterType} from "@process/types/process.types";
import {mapOfferFilterRequestToOfferFilterType as mapOfferFilter} from "@offer/utils/mapper";
import {Maybe} from "@src/types/global.types";
import {UserType} from "@user/types/user.types";
import {DictionaryType} from "@dictionary/types/dictionary.types";
import {ClientType} from "@client/types/client.types";
import {Col, Row} from "react-bootstrap";
import {UserSelect} from "@user/components";
import {DictionarySelect} from "@dictionary/components";
import {ClientSelect} from "@client/components";
import {DepartmentSelect} from "@department/components";
import {auth, AuthState} from "@auth/store/authSlice";
import {useSelector} from "react-redux";
import {AuthRoles} from "@auth/utils/AuthRoles";

interface OfferFilterProps {
    clientFilterRequest: OfferFilterRequestType
}

type FilterAllowTypes = Maybe<UserType|DictionaryType|ClientType>;

const OfferFilterComponent:React.FC<OfferFilterProps> = ({clientFilterRequest}): ReactElement => {
    const [filter, setFilter] = React.useState<ProcessFilterType>(mapOfferFilter(clientFilterRequest));
    const authData: AuthState = useSelector(auth);
    const hasAdminRole = !!authData.roles.find(role => role === AuthRoles.ROLE_ADMIN);

    const onFilterChangeHandler = (data: FilterAllowTypes, name: string) => {
        setFilter((prevState: ProcessFilterType) => ({...prevState, [name]: data}));
    }

    useEffect(() => setFilter(mapOfferFilter(clientFilterRequest)), [clientFilterRequest]);

    return (
        <FilterComponent>
            <Row className="pb-3">
                <Col xs="3">
                    <FilterSearch value={filter.search ?? ''} />
                </Col>
                <Col xs="3">
                    <UserSelect onChange={onFilterChangeHandler} name="createdBy" value={filter.createdBy} isClearable={true} placeholder="Dodana przez" />
                </Col>
                <Col xs="3">
                    <DictionarySelect
                        name="status"
                        model={"OfferStatus"}
                        onChange={onFilterChangeHandler}
                        value={filter.status}
                        isClearable={true}
                        placeholder="Status oferty"
                    />
                </Col>
                <Col xs="3">
                    <ClientSelect onChange={onFilterChangeHandler} name="client" value={filter.client} isClearable={true} placeholder="Klient" />
                </Col>
            </Row>
            {hasAdminRole && (
                <Row className="pb-3">
                    <Col xs={3}>
                        <DepartmentSelect
                            name="department"
                            onChange={onFilterChangeHandler}
                            value={filter.department}
                            isClearable={true}
                            placeholder="Oddział"
                        />
                    </Col>
                </Row>
            )}
        </FilterComponent>
    );
}

export default OfferFilterComponent;