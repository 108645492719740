import React, {useEffect} from "react";
import {Form} from "react-bootstrap";
import {ProcessItemPartType} from "@process/types/process.types";

import styles from './ProcessItemPart.module.scss';
import {updateProcessItemPart} from "@process/utils/axios";

interface ProcessItemPartProp {
    processItemPart: ProcessItemPartType,
    index: number,
    processId: string,
    processItemId: string,
}

export const ProcessItemPartComponent: React.FC<ProcessItemPartProp> = ({
    processItemPart, index, processId, processItemId,
}) => {
    const [part, setPart] = React.useState<ProcessItemPartType>({...processItemPart});
    const [isInited, setInited] = React.useState<boolean>(false);

    const onChangeAttachHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const isAttach = e.target.checked;
        setPart(prevState => {
            return {...prevState, isAttach: isAttach}
        });
    }

    const onBlurNoteHandler = (e: React.FocusEvent<HTMLInputElement>) => {
        const note = e.target.value;
        setPart(prevState => {
            return {...prevState, note: note}
        });
    }

    useEffect(() => {
        if (!isInited){
            setInited(true);
            return;
        }
        updateProcessItemPart(processId, processItemId, part);

    }, [part])

    return (
        <tr className={styles.partRow}>
            <td>{index + 1}</td>
            <td align="center">
                <Form.Check
                    type="switch"
                    name="isAttach"
                    id={`part-${part.id}`}
                    checked={part.isAttach}
                    onChange={onChangeAttachHandler}
                />
            </td>
            <td>
                <label htmlFor={`part-${part.id}`}>
                    {part.name}
                </label>
            </td>

            <td>
                <Form.Control
                    name="note"
                    type="text"
                    size="sm" defaultValue={part.note ?? ''}
                    onBlur={onBlurNoteHandler}
                />
            </td>
        </tr>
    );
};

export default ProcessItemPartComponent;