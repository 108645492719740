import React, {ChangeEventHandler, FormEventHandler, useState} from "react";
import {Button, Col, Form, InputGroup, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinnerThird} from "@fortawesome/pro-regular-svg-icons";
import {toast} from "react-toastify";
import {EstimateItemFormErrorType, EstimateItemType} from "@estimate/types/estimateItem.types";
import {Maybe} from "@src/types/global.types";
import {ProductSelect} from "@product/components";
import {ProductType} from "@product/types/product.types";
import {DictionarySelect} from "@dictionary/components";
import {FormError} from "@src/components";
import {mapEstimateItemToRequestType} from "@estimate/utils/mapper";
import {DictionaryType} from "@dictionary/types/dictionary.types";

interface EstimateItemFormProps {
    estimateItem: EstimateItemType,
    saveEstimateItem: Function,
    afterSuccessSave?: Maybe<Function>,
}

const EstimateItemFormComponent: React.FC<EstimateItemFormProps> = (
    {estimateItem: initEstimateItem, saveEstimateItem, afterSuccessSave}
) => {

    const [isSending, setSending] = useState<boolean>(false);
    const [formErrors, setFormErrors] = useState<EstimateItemFormErrorType>({});
    const [estimateItem, setEstimateItem] = useState<EstimateItemType>(initEstimateItem);

    const onChangeHandler: ChangeEventHandler<HTMLElement> = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value: string = e.target.value;
        const name: string = e.target.name;

        setEstimateItem(prevState => {
            return {...prevState, [name]: value};
        })
    }

    const onUnitChangeHandler = (unit: DictionaryType, name: Maybe<string>) => {
        setEstimateItem(prevState => {
            return {...prevState, unit: unit};
        })
    }

    const onProductChangeHandler = (product: Maybe<ProductType>) => {
        setEstimateItem((prevState: EstimateItemType) => {
            let newState = {...prevState, product: product};
            if (product) {
                newState.name = product.name;
                newState.sku = product.sku;
                newState.unitPriceGross = product.priceGross;
            }

            return newState
        });
    }

    const onSubmitHandler: FormEventHandler = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setSending(true);

        saveEstimateItem(mapEstimateItemToRequestType(estimateItem))
            .then(() => {
                toast.success("Zapisano");
                afterSuccessSave && afterSuccessSave();
            })
            .catch(error => {
                if (400 === error.response.status) {
                    const responseErrors = error.response?.data?.errors || {};
                    setFormErrors(responseErrors);
                } else {
                    console.log('Error', error.message);
                }
            })
            .finally(() => setSending(false))
    }

    return (
        <Form onSubmit={onSubmitHandler}>

            <Form.Group className="mb-3">
                <Form.Label>Produkt:</Form.Label>
                <ProductSelect
                    value={estimateItem.product}
                    onChange={onProductChangeHandler}
                    className={formErrors.product ? ' is-invalid' : ''}
                    isClearable={true}
                />
                <FormError errors={formErrors.product} />
            </Form.Group>

            <hr/>

            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label className="required">Nazwa pozycji:</Form.Label>
                        <Form.Control
                            value={estimateItem.name ?? ''}
                            onChange={onChangeHandler}
                            name="name"
                            isInvalid={!!formErrors.name}
                        />
                        <FormError errors={formErrors.name} />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>SKU:</Form.Label>
                        <Form.Control
                            value={estimateItem.sku ?? ''}
                            onChange={onChangeHandler}
                            name="name"
                            isInvalid={!!formErrors.sku}
                        />
                        <FormError errors={formErrors.sku} />
                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label className="required">Ilość:</Form.Label>
                        <Form.Control
                            value={estimateItem.quantity?.toString() ?? ''}
                            onChange={onChangeHandler}
                            name="quantity"
                            placeholder="Ilość"
                            isInvalid={!!formErrors.quantity}
                        />
                        <FormError errors={formErrors.quantity} />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Jednostka miary:</Form.Label>
                        <DictionarySelect
                            model="Unit"
                            name="unit"
                            value={estimateItem.unit}
                            onChange={onUnitChangeHandler}
                            required={true}
                            className={formErrors.unit ? ' is-invalid' : ''}
                        />
                        <FormError errors={formErrors.unit} />
                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <Col xs={6}>
                    <Form.Group className="mb-3">
                        <Form.Label className="required">Cena jednostkowa brutto:</Form.Label>
                        <InputGroup>
                            <Form.Control
                                value={estimateItem.unitPriceGross ?? ''}
                                onChange={onChangeHandler}
                                name="unitPriceGross"
                                placeholder="Cena jed. brutto"
                                isInvalid={!!formErrors.unitPriceGross}
                            />
                            <InputGroup.Text id="basic-addon1">zł</InputGroup.Text>
                            <FormError errors={formErrors.unitPriceGross} />
                        </InputGroup>
                    </Form.Group>
                </Col>
            </Row>


            <Form.Group className="mb-3">
                <Form.Label>Uwagi</Form.Label>
                <Form.Control as="textarea" name="note" rows={3}
                              onChange={onChangeHandler}
                              value={estimateItem.note ?? ''}
                              isInvalid={!!formErrors.note}
                />
                <FormError errors={formErrors.note} />
            </Form.Group>

            <Button type="submit" variant="primary" disabled={isSending}>
                {isSending ? (
                    <><FontAwesomeIcon icon={faSpinnerThird} spin={true}/> wysyłam</>
                ) : 'Zapisz'}
            </Button>
        </Form>
    );
};

export default EstimateItemFormComponent;