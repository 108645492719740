import {client as clientAxios} from "@src/library/axios.library";
import {AxiosResponse} from "axios";
import {PaginationDataType} from "@src/types/global.types";
import {OfferFilterRequestType, OfferRequestType, OfferInitRequest, OfferType} from "@offer/types/offer.types";

export const initOffer = async (requestBody?: OfferInitRequest): Promise<OfferType> => {
    const {data} = await clientAxios.post<OfferInitRequest, AxiosResponse<OfferType>>('/api/v1/offer/init', requestBody);
    return data;
}

export const createOffer = async (data: OfferRequestType): Promise<AxiosResponse> => {
    return await clientAxios.post<OfferRequestType, AxiosResponse>(`/api/v1/offer/create`, data);
}

export const updateOffer = async (offerId: string, data: OfferRequestType): Promise<AxiosResponse> => {
    return await clientAxios.patch<OfferRequestType, AxiosResponse>(`/api/v1/offer/${offerId}/update`, data);
}

export const getOfferCollection = async (filter: OfferFilterRequestType): Promise<PaginationDataType<OfferType>> => {
    const {data} = await clientAxios.post<OfferFilterRequestType, AxiosResponse<PaginationDataType<OfferType>>>('/api/v1/offer', filter);
    return data;
}

export const getOffer = async (id: string): Promise<OfferType> => {
    const {data} = await clientAxios.get<never, AxiosResponse<OfferType>>('/api/v1/offer/' + id);
    return data;
}

export const downloadOffer = async (offerId: string) => {
    return await clientAxios.get<never, AxiosResponse>('/api/v1/offer/' + offerId + '/download', {
        responseType: 'blob'
    });
}

export const cloneOffer = async (offerId: string) => {
    return await clientAxios.post<never, AxiosResponse>('/api/v1/offer/'+offerId+'/clone');
}

export const sendOfferEmail = async (offerId: string) => {
    return await clientAxios.post<never, AxiosResponse>('/api/v1/offer/'+offerId+'/send-email');
}
